import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material'
import { ExpandMore } from '@mui/icons-material'
import React, { useLayoutEffect, useState } from 'react'
import { auth } from '../../configs/firebase'

export const ServiceDetails = ({ details }) => {
  const [disabled, setDisabled] = useState(true)

  useLayoutEffect(() => {
    if (
      auth.currentUser.email === 'diegofs117@outlook.com' ||
      auth.currentUser.email === 'mat_preconsa@outlook.com' ||
      auth.currentUser.email === 'mat.preconsa_mauricio@hotmail.com'
    ) {
      setDisabled(false)
    }
  }, [setDisabled])

  const utilidad = (objeto) => {
    if (objeto.distancia < 30) {
      return (
        objeto.importe * 1 -
        (objeto.cCamino +
          objeto.cCombustible +
          objeto.cComision +
          objeto.cHhAyudante +
          objeto.cHhChofer +
          objeto.cImss +
          objeto.cInfonavit +
          objeto.cMantenimiento +
          objeto.cNeumaticos)
      )
    } else {
      return (
        objeto.importe * 1 -
        (objeto.cCamino +
          objeto.cCombustible +
          objeto.cComision +
          objeto.cHhAyudante +
          objeto.cHhChofer +
          objeto.cImss +
          objeto.cInfonavit +
          objeto.cMantenimiento +
          objeto.cNeumaticos)
      )
    }
  }

  const porcentaje = (objeto) => {
    if (objeto.distancia < 30) {
      let min = 0
      min = objeto.importe / 100
      return Math.round(utilidad(objeto) / min)
    } else {
      return objeto.cUtilidad
    }
  }

  return (
    <>
      <div className="large-group">
        <div className="small-group">
          <h3>Folio</h3>
          <label>{details.folio}</label>
        </div>
        <div className="small-group">
          <h3>Fecha</h3>
          <label>{details.fecha}</label>
        </div>
        <div className="small-group">
          <h3>Hora</h3>
          <label>{details.hora}</label>
        </div>
        <div className="micro-group">
          <h3>Cliente</h3>
          <label>{details.cliente}</label>
        </div>
        <div className="micro-group">
          <h3>Lugar</h3>
          <label>{details.lugar}</label>
        </div>
        <div className="micro-group">
          <h3>Estado</h3>
          <label>{details.estado}</label>
        </div>
        <div className="micro-group">
          <h3>Importe</h3>
          <label>${details.importe}</label>
        </div>
        <div className="micro-group">
          <h3>Forma de Pago</h3>
          <label>{details.pago}</label>
        </div>
      </div>
      <div className="large-group" style={{ marginTop: '20px' }}>
        <div className="small-group">
          <Accordion
            disabled={disabled}
            sx={{
              backgroundColor: '#009696',
              border: '1px solid #00544e',

              transition: '0.3s all ease',
              color: 'white',
              '&:hover': {
                backgroundColor: '#00544e',
                border: '1px solid #009696',
              },
            }}
          >
            <AccordionSummary
              expandIcon={<ExpandMore sx={{ color: 'white' }} />}
            >
              Desglose Unidad 1
            </AccordionSummary>
            <AccordionDetails>
              <div className="mini-group">
                <h3>Unidad</h3>
                <label>{details.unidad1}</label>
              </div>
              <div className="mini-group">
                <h3>Salidas</h3>
                <label>{details.salidas1}</label>
              </div>
              <div className="mini-group">
                <h3>Producto</h3>
                <label>{details.producto1}</label>
              </div>
              <div className="mini-group">
                <h3>Cantidad</h3>
                <label>{details.cantidad1}</label>
              </div>
            </AccordionDetails>
          </Accordion>
        </div>
        <div className="small-group">
          <Accordion
            disabled={disabled}
            sx={{
              backgroundColor: '#DEC65E',
              border: '1px solid #E9C52B',

              transition: '0.3s all ease',
              color: 'white',
              '&:hover': {
                backgroundColor: '#E9C52B',
                border: '1px solid #009696',
              },
            }}
          >
            <AccordionSummary
              expandIcon={<ExpandMore sx={{ color: 'white' }} />}
            >
              Desglose Unidad 2
            </AccordionSummary>
            <AccordionDetails>
              <div className="mini-group">
                <h3>Unidad</h3>
                <label>{details.unidad2}</label>
              </div>
              <div className="mini-group">
                <h3>Salidas</h3>
                <label>{details.salidas2}</label>
              </div>
              <div className="mini-group">
                <h3>Producto</h3>
                <label>{details.producto2}</label>
              </div>
              <div className="mini-group">
                <h3>Cantidad</h3>
                <label>{details.cantidad2}</label>
              </div>
            </AccordionDetails>
          </Accordion>
        </div>
        <div className="small-group">
          <Accordion
            disabled={disabled}
            sx={{
              backgroundColor: '#009696',
              border: '1px solid #00544e',

              transition: '0.3s all ease',
              color: 'white',
              '&:hover': {
                backgroundColor: '#00544e',
                border: '1px solid #009696',
              },
            }}
          >
            <AccordionSummary
              expandIcon={<ExpandMore sx={{ color: 'white' }} />}
            >
              Desglose Unidad 3
            </AccordionSummary>
            <AccordionDetails>
              <div className="mini-group">
                <h3>Unidad</h3>
                <label>{details.unidad3}</label>
              </div>
              <div className="mini-group">
                <h3>Salidas</h3>
                <label>{details.salidas3}</label>
              </div>
              <div className="mini-group">
                <h3>Producto</h3>
                <label>{details.producto3}</label>
              </div>
              <div className="mini-group">
                <h3>Cantidad</h3>
                <label>{details.cantidad3}</label>
              </div>
            </AccordionDetails>
          </Accordion>
        </div>
      </div>
      <div className="large-group" style={{ marginTop: '20px' }}>
        <Accordion
          disabled={disabled}
          sx={{
            backgroundColor: '#009696',
            border: '1px solid #00544e',

            transition: '0.3s all ease',
            color: 'white',
            '&:hover': {
              backgroundColor: '#00544e',
              border: '1px solid #009696',
            },
          }}
        >
          <AccordionSummary expandIcon={<ExpandMore sx={{ color: 'white' }} />}>
            Desglose de Costos
          </AccordionSummary>
          <AccordionDetails>
            <TableContainer component={Paper}>
              <Table aria-label="spanning table">
                <TableHead>
                  <TableRow>
                    <TableCell align="center" colSpan={3}>
                      Detalles
                    </TableCell>
                    <TableCell align="right">Importe</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell colSpan={2}>Concepto</TableCell>
                    <TableCell align="right">Valor</TableCell>
                    <TableCell align="right">Costo Unitario</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell colSpan={3}>Camino Accidentado</TableCell>
                    <TableCell align="right">${details.cCamino}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell colSpan={3}>Combustible</TableCell>
                    <TableCell align="right">${details.cCombustible}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell colSpan={3}>Comisión</TableCell>
                    <TableCell align="right">${details.cComision}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell colSpan={3}>Hora Hombre Ayudante</TableCell>
                    <TableCell align="right">${details.cHhAyudante}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell colSpan={3}>Hora Hombre Chofer</TableCell>
                    <TableCell align="right">${details.cHhChofer}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell colSpan={3}>Seguro Social</TableCell>
                    <TableCell align="right">${details.cImss}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell colSpan={3}>Infonavit</TableCell>
                    <TableCell align="right">${details.cInfonavit}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell colSpan={3}>Mantenimiento</TableCell>
                    <TableCell align="right">
                      ${details.cMantenimiento}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell colSpan={3}>Neumaticos</TableCell>
                    <TableCell align="right">${details.cNeumaticos}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell rowSpan={5} />
                    <TableCell colSpan={2}>Costo Total</TableCell>
                    <TableCell align="right">
                      $
                      {details.cCamino +
                        details.cCombustible +
                        details.cComision +
                        details.cHhAyudante +
                        details.cHhChofer +
                        details.cImss +
                        details.cInfonavit +
                        details.cMantenimiento +
                        details.cNeumaticos}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Utilidad %</TableCell>
                    <TableCell align="right">{porcentaje(details)} %</TableCell>
                    <TableCell align="right">${utilidad(details)}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell colSpan={2}>Importe Minimo</TableCell>
                    <TableCell align="right">${details.cTotal}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell colSpan={2}>Importe Cobrado</TableCell>
                    <TableCell align="right">${details.importe}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell colSpan={2}>Sobre Precio</TableCell>
                    <TableCell align="right">
                      ${details.importe - details.cTotal}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </AccordionDetails>
        </Accordion>
      </div>
    </>
  )
}
