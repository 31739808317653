import { getDatabase, onValue, push, ref, update } from 'firebase/database'
import { useLayoutEffect } from 'react'
import { useState } from 'react'
import { database } from '../configs/firebase'

export const FetchPay = () => {
  const [payments, setPayments] = useState([])

  useLayoutEffect(() => {
    onValue(
      ref(database, 'caja/'),
      (snapshot) => {
        const paymentsList = []
        snapshot.forEach((item) => {
          const paymentsItem = {
            id: item.key,
            ...item.val(),
          }
          paymentsList.push(paymentsItem)
        })
        setPayments(paymentsList)
      },
      (error) => {
        console.log(error)
      }
    )
  }, [])
  return [payments]
}

export const addPay = (importe, ticket, tipo_pago) => {
  const db = getDatabase()

  push(ref(db, 'caja'), {
    fecha: new Date().toLocaleDateString(),
    importe: importe,
    ticket: ticket,
    tipo_pago: tipo_pago
  })

  update(ref(db, `fletes/${ticket}/`), {
    estado: 'Pagada'
  })
}
