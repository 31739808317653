import React from 'react'

export const MessageDetails = ({ details }) => {
  return (
    <div className="large-group">
      <div className="mini-group">
        <h3>Fecha: </h3>
        <label>{details.fecha}</label>
      </div>
      <div className="mini-group">
        <h3>Cliente: </h3>
        <label>{details.nombre}</label>
      </div>
      <div className="mini-group">
        <h3>Correo: </h3>
        <label>{details.correo}</label>
      </div>
      <div className="mini-group">
        <h3>Telefono: </h3>
        <label>{details.telefono}</label>
      </div>
      <div className="special-group">
        <h3>Mensaje:</h3>
        <label>{details.mensaje}</label>
      </div>
    </div>
  )
}
