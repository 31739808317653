import { TextareaAutosize } from '@mui/material'

export const TextArea = (props) => {
  const { name, type, value, error = null, onChange, ...other } = props
  return (
    <TextareaAutosize
      style={{
        resize: 'none',
        width: '100%',
        height: '80px',
        backgroundColor: '#e9e9e9',
        borderRadius: '20px',
        fontSize: '14px',
        fontFamily: 'Nunito',
        border: '1px solid rgba(201, 201, 201, 0.711)',
        paddingLeft: '20px',
        paddingTop: '5px',
      }}
      type={type}
      variant="standard"
      autoComplete="off"
      name={name}
      value={value}
      onChange={onChange}
      {...other}
      {...(error && { error: true, helperText: error })}
    />
  )
}
