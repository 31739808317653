import logo from '../../../assets/bg-logo.webp'

import React, { Component } from 'react'

export class FooterAdmin extends Component {
  render() {
    return (
      <footer className="footer-distributed">
        <div className="footer-center">
          <div className="footer-distributed_logo-container">
            <img
              src={logo}
              className="footer-distributed_logo"
              alt="Logo Preconsa"
            />
          </div>
          <p className="footer-links">
            <a className="link-1" href="/info">
              Inicio
            </a>
            <a href="/calculator">Calcular Servicio</a>
            <a href="/places">Lugares</a>
            <a href="/cost">Costos</a>
            <a href="/deliveries">Entregas</a>
            <a href="/clients">Clientes</a>
            <a href="/messages">Mensajes</a>
            <a href="/blog">Blog</a>
          </p>
          <p className="footer-company-name">
            Materiales Preconsa S.A. de C.V. © 2022
          </p>
        </div>
      </footer>
    )
  }
}
