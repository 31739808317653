import { Input } from "./Input"
import { TextArea } from "./Textarea"
import Select from "./Select"

const Controls = {
    Input,
    Select,
    TextArea
}

export default Controls