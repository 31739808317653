import React, { useState, useLayoutEffect } from 'react'
import { useDispatch } from 'react-redux'

import { logout } from '../../../redux/actions/auth'

export const NavbarUser = () => {
  const dispatch = useDispatch()

  const handleLogout = () => {
    dispatch(logout())
  }

  const [menu, setMenu] = useState(false)

  useLayoutEffect(() => {
    setMenu(false)
  }, [setMenu])

  return (
    <div>
      <div className="navbar">
        <div className="navigation">
          <input
            type="checkbox"
            className="navigation__checkbox"
            id="navi-toggle"
            onClick={(e) => {
              if (menu === false) {
                setMenu(true)
              } else {
                setMenu(false)
              }
            }}
          ></input>
          <label htmlFor="navi-toggle" className="navigation__button">
            <span className="navigation__icon">&nbsp;</span>
          </label>
          <div className="navigation__background">&nbsp;</div>
          <nav className="navigation__nav">
            {menu === true && (
              <ul className="navigation__list">
                <li className="navigation__item">
                  <a href="/info" className="navigation__link">
                    Inicio
                  </a>
                </li>
                <li className="navigation__item">
                  <a href="/calculator" className="navigation__link">
                    Calcular Servicio
                  </a>
                </li>
                <li className="navigation__item">
                  <a href="/places" className="navigation__link">
                    Lugares
                  </a>
                </li>
                <li>
                  <a href="/deliveries" className="navigation__link">
                    Entregas
                  </a>
                </li>
                <li>
                  <a href="/clients" className="navigation__link">
                    Clientes
                  </a>
                </li>
                <li className="navigation__item">
                  <a href="/messages" className="navigation__link">
                    Mensajes
                  </a>
                </li>
              </ul>
            )}
          </nav>
        </div>

        <label className="navbar_left">Bienvenido - Ventas</label>
        <div className="navbar_right">
          <button className="navbar_right_button" onClick={handleLogout}>
            Cerrar Sesión
          </button>
        </div>
      </div>
      <header>Servicios a Domicilio</header>
    </div>
  )
}
