import jsPDF from 'jspdf'
import 'jspdf-autotable'

import logo from '../assets/bg-logo.png'

import { onValue, ref } from 'firebase/database'
import { database } from '../configs/firebase'
import { fecha, formatDate /*msToTime*/ } from './datetime'

import { showTicketNumber } from '../helpers/cashFunctions'
import { costosValues } from './cost'

export const generateCash = () => {
  let amountDay = 0
  let amount = []
  let amountList = []

  let transferDay = 0
  let transfer = []
  let transferList = []

  let servicesDay = 0
  let servicesAmount = []
  let servicesList = []

  onValue(ref(database, 'caja/'), (snapshot) => {
    snapshot.forEach((item) => {
      const serviceItem = [
        item.val().fecha,
        showTicketNumber(item.val().ticket),
        item.val().tipo_pago,
        '$ ' + item.val().importe,
      ]

      if (serviceItem[0] === new Date().toLocaleDateString()) {
        servicesList.push(serviceItem)
        servicesAmount.push(item.val().importe * 1)

        if (item.val().tipo_pago === 'De Contado') {
          amount.push(serviceItem)
          amountList.push(item.val().importe * 1)
        } else if (
          item.val().tipo_pago === 'Con Tarjeta' ||
          item.val().tipo_pago === 'Por Transferencia'
        ) {
          transfer.push(serviceItem)
          transferList.push(item.val().importe * 1)
        }
      }
    })
  })
  servicesDay = servicesAmount.reduce((x, y) => x + y, 0)
  transferDay = transferList.reduce((x, y) => x + y, 0)
  amountDay = amountList.reduce((x, y) => x + y, 0)

  var doc = new jsPDF('p', 'pt', 'letter')

  doc.addImage(logo, 'PNG', 30, 30, 125, 50)
  doc.text(235, 45, 'MATERIALES PRECONSA SA DE CV')
  doc.text(165, 65, 'CORTE DE CAJA SERVICIOS A DOMICILIO DEL ' + fecha())
  doc.text(165, 75, '________________________________________________')

  doc.text(225, 110, 'Pagos Realizados')
  doc.text(250, 135, 'Total: $ ' + servicesDay)
  doc.autoTable({
    head: [['Fecha', 'Ticket', 'Tipo de Pago', 'Importe']],
    body: servicesList,
    margin: { top: 145 },
    theme: 'grid',
    styles: {
      fontSize: 10,
    },
  })

  doc.addPage('letter', 'p')

  doc.addImage(logo, 'PNG', 30, 30, 125, 50)
  doc.text(235, 45, 'MATERIALES PRECONSA SA DE CV')
  doc.text(165, 65, 'CORTE DE CAJA SERVICIOS A DOMICILIO DEL ' + fecha())
  doc.text(165, 75, '________________________________________________')
  doc.text(225, 110, 'Ticket Pagados de Contado')
  doc.text(250, 135, 'Total: $ ' + amountDay)
  doc.autoTable({
    head: [['Fecha', 'Ticket', 'Tipo de Pago', 'Importe']],
    body: amount,
    margin: { top: 145 },
    theme: 'grid',
    styles: {
      fontSize: 10,
    },
  })

  doc.addPage('letter', 'p')

  doc.addImage(logo, 'PNG', 30, 30, 125, 50)
  doc.text(235, 45, 'MATERIALES PRECONSA SA DE CV')
  doc.text(165, 65, 'CORTE DE CAJA SERVICIOS A DOMICILIO DEL ' + fecha())
  doc.text(165, 75, '________________________________________________')
  doc.text(225, 110, 'Ticket Pagados por Transferencia o Tarjeta')
  doc.text(250, 135, 'Total: $ ' + transferDay)
  doc.autoTable({
    head: [['Fecha', 'Ticket', 'Tipo de Pago', 'Importe']],
    body: transfer,
    margin: { top: 145 },
    theme: 'grid',
    styles: {
      fontSize: 10,
    },
  })

  doc.autoPrint()
  doc.output('dataurlnewwindow', { filename: 'Corte.pdf' })
}

export const generateTicket = (print) => {
  var doc = new jsPDF('p', 'pt', [228, 3508])

  doc.addImage(logo, 'WEBP', 50, 30, 125, 50)
  doc.setFontSize(8)
  doc.text(55, 95, 'Carr. Ciudad Hidalgo-Maravatío')
  doc.text(62.5, 105, 'Ciudad Hidalgo, Michoacán')
  doc.text(70, 115, 'Tel. 01(786)-168-01-64')
  doc.text(
    20,
    125,
    '----------------------------------------------------------------------'
  )
  doc.text(20, 135, 'Folio')
  doc.text(print.folio + '', 205, 135, { align: 'right' })
  doc.text(20, 145, 'Fecha')
  doc.text(205, 145, print.fecha, { align: 'right' })
  doc.text(20, 155, 'Hora')
  doc.text(205, 155, print.hora, { align: 'right' })
  doc.text(
    20,
    165,
    '----------------------------------------------------------------------'
  )
  doc.text(20, 175, 'Nombre:')
  doc.text(205, 175, print.cliente, { align: 'right' })
  //doc.text(20, 185, 'Lugar de Entrega:')
  doc.text(20, 185, 'Lugar de Entrega: ' + print.lugar, {
    maxWidth: '185',
    align: 'justify',
  })
  doc.text(20, 205, 'Referencia:   ' + print.referencia, {
    maxWidth: '185',
    align: 'justify',
  })
  doc.text(
    20,
    255,
    '----------------------------------------------------------------------'
  )
  doc.text(20, 265, 'Descripción')
  doc.text(205, 265, 'Servicio a Domicilio', { align: 'right' })
  doc.text(20, 275, 'Importe')
  doc.text(205, 275, '$ ' + print.importe, { align: 'right' })
  doc.text(
    20,
    285,
    '----------------------------------------------------------------------'
  )
  doc.text(20, 295, 'Total a Pagar')
  doc.text(205, 295, '$ ' + print.importe, { align: 'right' })
  doc.text(70, 320, '¡Gracias por su compra!')

  doc.autoPrint()
  doc.output('dataurlnewwindow', { filename: 'Ticket ' + print.folio + '.pdf' })
}

export const weeklyDeliveries = (
  date1,
  date2,
  trabajador,
  horas,
  dias,
  bpa,
  bpcr
) => {
  const cost = costosValues

  const kenworth = []
  const inter = []
  const ford = []
  const ford2014 = []
  const nissan = []
  const pzs = []
  const list = []

  //comisiones, retenciones y totales
  let comKenworth = 0
  let comCamion = 0
  let comDoble = 0
  let comNissan = 0
  let comPzCargadas = 0
  let comBonos = 0
  let subtotal = 0
  let total = 0

  //const weeklyTime = 176400000
  let factoryTime = 0

  const limit = date2
  limit.setDate(limit.getDate() + 1)

  let tempTrabajador = {
    nombre: '',
    puesto: '',
  }

  //busca trabajador por id
  onValue(ref(database, `choferes/${trabajador}`), (snapshot) => {
    if (snapshot.exists()) {
      tempTrabajador = snapshot.val()
    } else {
      console.log('Trabajador no encontrado')
    }
  })

  //busca entregas donde el trabajador haya participado
  onValue(ref(database, 'entregas/'), (snapshot) => {
    snapshot.forEach((item) => {
      if (
        item.val().date >= new Date(date1).setHours(0) &&
        item.val().date <= limit
      ) {
        let time = 0
        const trip = [
          item.val().fecha,
          item.val().chofer,
          item.val().unidad,
          item.val().producto,
          item.val().ayudante,
          item.val().ayudante2,
        ]
        if (item.val().chofer === tempTrabajador.nombre) {
          switch (item.val().unidad) {
            case 'Kenworth':
              kenworth.push(1)
              break
            case 'International':
              inter.push(1)
              break
            case 'Camión Ford':
              ford.push(1)
              break
            case 'Camioneta Ford':
              ford2014.push(1)
              break
            case 'Nissan 2014':
              nissan.push(1)
              break
            case 'Recogen (Camioneta)':
            case 'Recogen (Camion)':
            case 'Recogen (Torton)':
              //Divide la cadena por las comas para obtener cada producto
              const productos = item.val().producto.split(', ')

              //Filtra los productos que no sean block o tabicon y extrae los numeros
              const numerosFiltrados = productos
                .filter(
                  (item) =>
                    !item.includes('CPC30R') &&
                    !item.includes('MORT') &&
                    !item.includes('VAR3/8') &&
                    !item.includes('CPC-MORT') &&
                    !item.includes('Block Macizo') &&
                    !item.includes('Poste 2x10x10') &&
                    !item.includes('Poste 2.5x10x10') &&
                    !item.includes('Poste 2x15x15') &&
                    !item.includes('Poste 2.5x15x15') &&
                    !item.includes('TC6') &&
                    !item.includes('TC8') &&
                    !item.includes('TC10') &&
                    !item.includes('TC12') &&
                    !item.includes('TC15') &&
                    !item.includes('TC18') &&
                    !item.includes('TC24') &&
                    !item.includes('Brocal')
                )
                .map((item) => parseInt(item))

              // Suma los números en el array resultante
              const sumaTotal = numerosFiltrados.reduce(
                (total, num) => total + num,
                0
              )
              pzs.push(sumaTotal)
              break
            default:
              break
          }
          list.push(trip)
          time = item.val().coming - item.val().start
          factoryTime = factoryTime + time
        } else if (
          (item.val().ayudante === tempTrabajador.nombre ||
            item.val().ayudante2 === tempTrabajador.nombre) &&
          item.val().unidad !== 'Nissan 2014'
        ) {
          const productos = item.val().producto.split(', ')

          //Filtra los productos que no sean block o tabicon y extrae los numeros
          const numerosFiltrados = productos
            .filter(
              (item) =>
                !item.includes('CPC30R') &&
                !item.includes('MORT') &&
                !item.includes('VAR3/8') &&
                !item.includes('Poste 2x10x10') &&
                !item.includes('Poste 2.5x10x10') &&
                !item.includes('Poste 2x15x15') &&
                !item.includes('Poste 2.5x15x15') &&
                !item.includes('TC6') &&
                !item.includes('TC8') &&
                !item.includes('TC10') &&
                !item.includes('TC12') &&
                !item.includes('TC15') &&
                !item.includes('TC18') &&
                !item.includes('TC24') &&
                !item.includes('Brocal')
            )
            .map((item) => parseInt(item))

          // Suma los números en el array resultante
          const sumaTotal = numerosFiltrados.reduce(
            (total, num) => total + num,
            0
          )
          pzs.push(sumaTotal)
        }
      }
    })
  })
  //let time = weeklyTime - factoryTime

  //Operaciones
  if (tempTrabajador.puesto === 'Chofer') {
    //comisiones viajes chofer
    comKenworth = cost.comKenworth * kenworth.length
    comCamion = cost.com10ton * (inter.length + ford.length)
    comDoble = cost.com3ton * ford2014.length
    comNissan = cost.com1ton * nissan.length
    if (bpa === '1') {
      comBonos = comBonos + 120
    }
    if (bpcr === '1') {
      if (tempTrabajador.nombre === 'Rafael') {
        comBonos = comBonos + 465.49
      } else {
        comBonos = comBonos + 149.99
      }
    }
  } else {
    //comisiones viajes ayudantes
    comKenworth = cost.comKenworthAyudante * kenworth.length
    comCamion = cost.com10tonAyudante * (inter.length + ford.length)
    comDoble = cost.com3tonAyudante * ford2014.length
    comNissan = 0
    if (bpa === '1') {
      comBonos = comBonos + 60
    }
  }

  comPzCargadas = (
    pzs.reduce((acumulador, numero) => acumulador + numero, 0) * 0.02
  ).toFixed(2)

  subtotal =
    comKenworth * 1 +
    comCamion * 1 +
    comDoble * 1 +
    comNissan * 1 +
    comPzCargadas * 1 +
    comBonos * 1 +
    dias * 290.42 +
    horas * 29.04

  total = Math.round(subtotal - 70.17)

  let doc = new jsPDF('p', 'pt', 'letter')

  doc.addImage(logo, 'PNG', 30, 30, 125, 50)
  doc.text(235, 45, 'MATERIALES PRECONSA SA DE CV')
  doc.text(
    160,
    65,
    'REPORTE DE TRABAJADOR DEL ' +
      formatDate(date1) +
      ' AL ' +
      formatDate(date2)
  )
  doc.text(165, 75, '________________________________________________')
  doc.text(250, 115, 'Trabajador')
  doc.text(50, 140, 'Nombre: ' + tempTrabajador.nombre)
  doc.text(50, 160, 'Puesto: ' + tempTrabajador.puesto)

  doc.text(230, 200, 'Desglose de Sueldo')

  //Calculo días laborados
  doc.text(200, 230, 'Núm. de Días')
  doc.text(340, 230, 'Sueldo diario')
  doc.text(500, 230, 'Total')
  doc.text(50, 250, '• Días laborados: ')
  doc.text(200, 250, `${dias} días`)
  doc.text(290, 250, 'x')
  doc.text(340, 250, '$')
  doc.text(420, 250, `290.42`, { align: 'right' })
  doc.text(430, 250, '=')
  doc.text(460, 250, '$')
  doc.text(550, 250, `${(dias * 290.42).toFixed(2)}`, { align: 'right' })

  //Calculo viajes realizados
  doc.text(200, 280, 'Núm. de Viajes')
  doc.text(340, 280, 'Comisión')
  doc.text(500, 280, 'Total')
  doc.text(50, 280, '• Viajes:')
  doc.text(70, 300, '• Kenworth:')
  doc.text(200, 300, `${kenworth.length} viajes`)
  doc.text(290, 300, 'x')
  doc.text(340, 300, '$')
  doc.text(
    420,
    300,
    `${(kenworth.length === 0 ? 0 : comKenworth / kenworth.length).toFixed(2)}`,
    { align: 'right' }
  )
  doc.text(430, 300, '=')
  doc.text(460, 300, '$')
  doc.text(550, 300, `${comKenworth.toFixed(2)}`, { align: 'right' })
  doc.text(70, 320, '• Inter/Ford:')
  doc.text(200, 320, `${inter.length + ford.length} viajes`)
  doc.text(290, 320, 'x')
  doc.text(340, 320, '$')
  doc.text(
    420,
    320,
    `${(inter.length + ford.length === 0
      ? 0
      : comCamion / (inter.length + ford.length)
    ).toFixed(2)}`,
    { align: 'right' }
  )
  doc.text(430, 320, '=')
  doc.text(460, 320, '$')
  doc.text(550, 320, `${comCamion.toFixed(2)}`, { align: 'right' })
  doc.text(70, 340, '• Ford 2014:')
  doc.text(200, 340, `${ford2014.length} viajes`)
  doc.text(290, 340, 'x')
  doc.text(340, 340, '$')
  doc.text(
    420,
    340,
    `${(ford2014.length === 0 ? 0 : comDoble / ford2014.length).toFixed(2)}`,
    { align: 'right' }
  )
  doc.text(430, 340, '=')
  doc.text(460, 340, '$')
  doc.text(550, 340, `${comDoble.toFixed(2)}`, { align: 'right' })
  doc.text(70, 360, '• Nissan:')
  doc.text(200, 360, `${nissan.length} viajes`)
  doc.text(290, 360, 'x')
  doc.text(340, 360, '$')
  doc.text(
    420,
    360,
    `${(nissan.length === 0 ? 0 : comNissan / nissan.length).toFixed(2)}`,
    { align: 'right' }
  )
  doc.text(430, 360, '=')
  doc.text(460, 360, '$')
  doc.text(550, 360, `${comNissan.toFixed(2)}`, { align: 'right' })

  doc.text(550, 361, `__________`, { align: 'right' })
  doc.text(460, 380, '$')
  doc.text(
    550,
    380,
    `${(comKenworth * 1 + comCamion * 1 + comDoble * 1 + comNissan * 1).toFixed(
      2
    )}`,
    { align: 'right' }
  )

  //Calculo de piezas cargadas en fabrica
  doc.text(200, 410, 'Núm. de Piezas')
  doc.text(340, 410, 'Monto/Pieza')
  doc.text(500, 410, 'Total')
  doc.text(50, 430, '• Piezas Cargadas:')
  doc.text(
    200,
    430,
    `${pzs.reduce((acumulador, numero) => acumulador + numero, 0)} pzs`
  )
  doc.text(290, 430, 'x')
  doc.text(340, 430, '$')
  doc.text(420, 430, `0.02`, { align: 'right' })
  doc.text(430, 430, '=')
  doc.text(460, 430, '$')
  doc.text(550, 430, `${comPzCargadas}`, { align: 'right' })

  //Calculo de bonos
  doc.text(500, 460, 'Total')
  doc.text(50, 460, '• Bonos:')
  doc.text(70, 480, '• Puntualidad/Asistencia:')
  doc.text(460, 480, '$')
  doc.text(430, 480, '=')
  doc.text(70, 500, '• Chofer Responsable:')
  doc.text(460, 500, '$')
  doc.text(430, 500, '=')
  if (tempTrabajador.puesto === 'Chofer') {
    if (bpa === '1') {
      doc.text(550, 480, `120.00`, { align: 'right' })
    } else {
      doc.text(550, 480, `0.00`, { align: 'right' })
    }
    if (tempTrabajador.nombre === 'Rafael') {
      if (bpcr === '1') {
        doc.text(550, 500, `465.49`, { align: 'right' })
      } else {
        doc.text(550, 500, `0.00`, { align: 'right' })
      }
    } else {
      if (bpcr === '1') {
        doc.text(550, 500, `149.99`, { align: 'right' })
      } else {
        doc.text(550, 500, `0.00`, { align: 'right' })
      }
    }
  } else {
    if (bpa === '1') {
      doc.text(550, 480, `60.00`, { align: 'right' })
    } else {
      doc.text(550, 480, `0.00`, { align: 'right' })
    }
    doc.text(550, 500, ` N/A`, { align: 'right' })
  }

  //Calculo Horas extra
  doc.text(200, 530, 'Núm. de Horas')
  doc.text(340, 530, 'Sueldo/Hora')
  doc.text(500, 530, 'Total')
  doc.text(50, 550, '• Horas Extra')
  doc.text(200, 550, `${horas} horas`)
  doc.text(290, 550, 'x')
  doc.text(340, 550, '$')
  doc.text(420, 550, `29.04`, { align: 'right' })
  doc.text(460, 550, '$')
  doc.text(430, 550, '=')
  doc.text(550, 550, `${(horas * 29.04).toFixed(2)}`, { align: 'right' })

  //Calculo de totales y retenciones
  doc.text(400, 580, 'Subtotal', { align: 'right' })
  doc.text(430, 580, '=')
  doc.text(460, 580, '$')
  doc.text(550, 580, `${subtotal.toFixed(2)}`, { align: 'right' })
  doc.text(400, 600, 'Retenciones', { align: 'right' })
  doc.text(430, 600, '=')
  doc.text(460, 600, '$')
  doc.text(550, 600, `-70.17`, { align: 'right' })
  doc.text(550, 601, `__________`, { align: 'right' })
  doc.text(400, 620, 'Sueldo semanal', { align: 'right' })
  doc.text(430, 620, '=')
  doc.text(460, 620, '$')
  doc.text(550, 620, `${total}.00`, { align: 'right' })

  doc.autoPrint()
  doc.output('dataurlnewwindow', {
    filename: 'Viajes' + tempTrabajador.nombre + '.pdf',
  })
}

export const statusReportService = (date1, date2, unidad) => {
  let tickets = 0
  let costos = 0
  let combustible = 0
  let comisionChofer = 0
  let comisionAyudante = 0
  let sueldoChofer = 0
  let sueldoAyudante = 0
  let imss = 0
  let infonavit = 0
  let mantenimiento = 0
  let neumaticos = 0

  const limit = date2
  limit.setDate(limit.getDate() + 1)

  onValue(ref(database, 'fletes/'), (snapshot) => {
    snapshot.forEach((item) => {
      if (item.val().horaSistema >= date1 && item.val().horaSistema <= limit) {
        if (
          item.val().unidad1 === unidad ||
          item.val().unidad2 === unidad ||
          item.val().unidad3 === unidad
        ) {
          tickets = tickets + item.val().importe * 1
          combustible = combustible + item.val().cCombustible
          sueldoChofer = sueldoChofer + item.val().cHhChofer
          sueldoAyudante = sueldoAyudante + item.val().cHhAyudante
          imss = imss + item.val().cImss
          infonavit = infonavit + item.val().cInfonavit
          mantenimiento = mantenimiento + item.val().cMantenimiento
          neumaticos = neumaticos + item.val().cNeumaticos
          comisionChofer = comisionChofer + item.val().cComision
        } else if (unidad === 'Todas las Unidades') {
          tickets = tickets + item.val().importe * 1
          combustible = combustible + item.val().cCombustible
          sueldoChofer = sueldoChofer + item.val().cHhChofer
          sueldoAyudante = sueldoAyudante + item.val().cHhAyudante
          imss = imss + item.val().cImss
          infonavit = infonavit + item.val().cInfonavit
          mantenimiento = mantenimiento + item.val().cMantenimiento
          neumaticos = neumaticos + item.val().cNeumaticos
          comisionChofer = comisionChofer + item.val().cComision
        }
      }
    })
  })

  costos =
    combustible +
    sueldoChofer +
    sueldoAyudante +
    imss +
    infonavit +
    mantenimiento +
    neumaticos +
    comisionChofer +
    comisionAyudante

  let utilidad = tickets - costos

  let doc = new jsPDF('p', 'pt', 'letter')

  doc.addImage(logo, 'PNG', 30, 30, 125, 50)
  doc.text(235, 45, 'MATERIALES PRECONSA SA DE CV')
  doc.text(
    165,
    65,
    'ESTADO DE RESULTADOS DEL ' + formatDate(date1) + ' AL ' + formatDate(date2)
  )
  doc.text(285, 85, 'EN BASE A ' + unidad)
  doc.text(
    30,
    95,
    '________________________________________________________________'
  )

  doc.text(50, 130, '• Ingresos')
  doc.text(500, 130, `$ ${tickets}`)
  doc.text(50, 160, '• Gastos')
  doc.text(80, 180, '• Combustible')
  doc.text(430, 180, `$ ${combustible}`)
  doc.text(80, 200, '• Neumaticos')
  doc.text(430, 200, `$ ${neumaticos}`)
  doc.text(80, 220, '• Mantenimiento')
  doc.text(430, 220, `$ ${mantenimiento}`)
  doc.text(80, 240, '• Sueldo Chofer')
  doc.text(430, 240, `$ ${sueldoChofer}`)
  doc.text(80, 260, '• Sueldo Ayudante')
  doc.text(430, 260, `$ ${sueldoAyudante}`)
  doc.text(80, 280, '• Comisiones')
  doc.text(430, 280, `$ ${comisionChofer}`)
  doc.text(80, 300, '• IMSS')
  doc.text(430, 300, `$ ${imss}`)
  doc.text(80, 320, '• Infonavit')
  doc.text(430, 320, `$ ${infonavit}`)
  doc.text(50, 350, '• Total Gastos')
  doc.text(500, 350, `$ ${costos}`)
  doc.text(50, 370, '• Utilidad')
  doc.text(500, 370, `$ ${utilidad}`)

  //doc.autoPrint()
  doc.output('dataurlnewwindow', {
    filename: 'Estado de Resultados ' + unidad + '.pdf',
  })
}

export const driverReport = (info) => {
  let doc = new jsPDF('p', 'pt', [228, 3508])

  doc.addImage(logo, 'PNG', 50, 30, 125, 50)
  doc.setFontSize(12)
  doc.text(35, 95, 'Reporte de Pedido de Chofer')

  doc.setFontSize(8)
  doc.text(
    20,
    105,
    '----------------------------------------------------------------------'
  )
  doc.text(25, 115, 'Fecha')
  doc.text(205, 115, info.fecha, { align: 'right' })
  doc.text(25, 125, 'Cliente')
  doc.text(205, 125, info.cliente, { align: 'right' })
  doc.text(25, 135, 'Producto')
  doc.text(205, 135, info.producto, { align: 'right' })
  doc.text(25, 145, 'Lugar')
  doc.text(205, 145, info.lugar, { align: 'right' })
  doc.text(25, 155, 'Personal')
  doc.text(205, 155, `${info.chofer}, ${info.ayudante}, ${info.ayudante2}`, {
    align: 'right',
  })
  doc.text(25, 165, 'Hora de Inicio de Carga')
  doc.text(205, 165, info.hrInicio, { align: 'right' })
  doc.text(25, 185, 'Hora de Salida de Planta')
  doc.text(205, 185, '_____________', { align: 'right' })
  doc.text(25, 205, 'Hora de Llegada a Planta')
  doc.text(205, 205, '_____________', { align: 'right' })
  doc.text(25, 225, 'Hora Estimada de Regreso')
  doc.text(205, 225, '_____________', { align: 'right' })
  doc.text(
    20,
    235,
    '----------------------------------------------------------------------'
  )

  doc.output('dataurlnewwindow', { filename: 'Reporte ' + info.date + '.pdf' })
}
