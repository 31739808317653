import {
  TableBody,
  TableRow,
  TableCell,
  Toolbar,
  InputAdornment,
  TextField,
  Button,
} from '@mui/material'
import { makeStyles } from '@material-ui/core'
import { Add, Search } from '@mui/icons-material'

import useTable from '../UI/Table'

import { useState } from 'react'
import { CustomDialog } from '../UI/Dialog'

import { CashForm } from './cashForm'
import { generateCash } from '../../services/reports'
import { FetchPay } from '../../services/cash'
import { showTicketNumber } from '../../helpers/cashFunctions'
import { StatusReport } from './statusReport'

const headCells = [
  { id: 'fecha', label: 'Fecha' },
  { id: 'ticket', label: 'Ticket' },
  { id: 'importe', label: 'Importe' },
  { id: 'tipo_pago', label: 'Tipo de Pago' },
]

const useStyles = makeStyles(() => ({
  textField: {
    width: '100%',
    outline: '0',
    marginBottom: '20px',
    borderRadius: '20px',
    border: '0',
    backgroundColor: '#e9e9e9',
    fontSize: '14px',
    appearance: 'none',
    resize: 'none',
    padding: '2px',
  },
  input: {
    border: 'none',
    height: '2rem',
  },
  iconButton: {
    width: '2rem',
    height: '2rem',
    marginRight: '-10px',
  },
  selectButton: {
    backgroundColor: '#00544e',
    color: 'white',
    height: '30px',
    width: '30px',
    transition: '0.3s all ease',
    '&:hover': {
      backgroundColor: '#009696',
      border: '1px solid #00544e',
    },
  },
  searchInput: {
    width: '100%',
    marginRight: '5%',
  },
  toolBar: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    marginBottom: '15px',
  },
  '@media (max-width: 700px)': {
    toolBar: {
      display: 'list-item',
      flexWrap: 'wrap',
    },
    margin: {
      marginTop: '20px',
    },
  },
}))

export const Cash = () => {
  const [payments] = FetchPay()

  const classes = useStyles()

  const [filterFn, setFilterFn] = useState({
    fn: (items) => {
      return items
    },
  })

  const [open, setOpen] = useState(false)
  const [estado, setEstado] = useState(false)

  const { TblContainer, TblHead, TblPagination, recordAfterPagingAndSorting } =
    useTable(payments, headCells, filterFn)

  const handleSearch = ({ target }) => {
    const { value } = target
    setFilterFn({
      fn: (items) => {
        if (target.value === '') return items
        else
          return items.filter((x) =>
            x.cliente.toLowerCase().includes(value.toLowerCase())
          )
      },
    })
  }

  return (
    <>
      <div style={{ marginTop: '15px', marginBottom: '30px' }}>
        <Toolbar className={classes.toolBar}>
          <div className="special-group">
            <TextField
              variant="standard"
              className={classes.searchInput}
              sx={{
                height: '40px',
                borderRadius: '25px',
                backgroundColor: '#e9e9e9',
                fontSize: '14px',
                padding: '5px',
                marginBottom: '15px',
              }}
              placeholder="Buscar pago"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start" sx={{ marginLeft: '10px' }}>
                    <Search />
                  </InputAdornment>
                ),
                disableUnderline: true,
              }}
              onChange={handleSearch}
            />
          </div>
          <div className="small-group">
            <Button
              variant="contained"
              startIcon={<Add />}
              sx={{
                backgroundColor: '#009696',
                padding: '12px 15px',
                height: '40px',
                fontSize: '1em;',
                border: '1px solid #00544e',
                borderRadius: '30px',
                transition: '0.3s all ease',
                color: 'secondary',
                '&:hover': {
                  backgroundColor: '#00544e',
                  border: '1px solid #009696',
                },
              }}
              onClick={() => {
                generateCash()
              }}
            >
              Generar Corte
            </Button>
          </div>
          <div className="small-group">
            <Button
              variant="contained"
              startIcon={<Add />}
              sx={{
                backgroundColor: '#009696',
                padding: '12px 15px',
                height: '40px',
                fontSize: '1em;',
                border: '1px solid #00544e',
                borderRadius: '30px',
                transition: '0.3s all ease',
                color: 'secondary',
                '&:hover': {
                  backgroundColor: '#00544e',
                  border: '1px solid #009696',
                },
              }}
              onClick={() => {
                setOpen(true)
              }}
            >
              Agregar Pago
            </Button>
          </div>
          <div className="small-group">
            <Button
              variant="contained"
              sx={{
                backgroundColor: '#009696',
                padding: '12px 15px',
                height: '40px',
                fontSize: '1em;',
                border: '1px solid #00544e',
                borderRadius: '30px',
                transition: '0.3s all ease',
                color: 'secondary',
                '&:hover': {
                  backgroundColor: '#00544e',
                  border: '1px solid #009696',
                },
              }}
              onClick={() => {
                setEstado(true)
              }}
            >
              Estado de Resultados
            </Button>
          </div>
        </Toolbar>
        <TblContainer>
          <TblHead />
          <TableBody>
            {recordAfterPagingAndSorting().map((item) => (
              <TableRow key={item.id}>
                <TableCell
                  data-label="Fecha"
                  style={{ fontSize: '14px', fontFamily: 'Nunito' }}
                >
                  {item.fecha}
                </TableCell>
                <TableCell
                  data-label="Ticket"
                  style={{ fontSize: '14px', fontFamily: 'Nunito' }}
                >
                  {showTicketNumber(item.ticket)}
                </TableCell>
                <TableCell
                  data-label="Importe"
                  style={{ fontSize: '14px', fontFamily: 'Nunito' }}
                >
                  $ {item.importe}
                </TableCell>
                <TableCell
                  data-label="Tipo de Pago"
                  style={{ fontSize: '14px', fontFamily: 'Nunito' }}
                >
                  {item.tipo_pago}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </TblContainer>
        <TblPagination />
        <CustomDialog
          title="Agregar Pago"
          openPopup={open}
          setOpenPopup={setOpen}
          maxWidth="md"
        >
          <CashForm setOpenPopup={setOpen} />
        </CustomDialog>
        <CustomDialog
          title="Estado de Resultados"
          openPopup={estado}
          setOpenPopup={setEstado}
          maxWidth="md"
        >
          <StatusReport setOpenPopup={setEstado} />
        </CustomDialog>
      </div>
    </>
  )
}
