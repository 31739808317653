import React, { Component } from 'react'

export class Information extends Component {
  render() {
    return (
      <div style={{ marginBottom: '10vh' }}>
        <div className="row">
          <div className="col">
            <div className="tabs">
              {/* Unidades */}
              <div className="tab">
                <input className="inputview" type="checkbox" id="chck1" />
                <label className="tab-label" htmlFor="chck1">
                  Unidades
                </label>
                <div className="tab-content">
                  <div>
                    <div className="col">
                      <div className="tabs">
                        {/* Nissan 2014 */}
                        <div className="tab">
                          <input
                            className="inputview"
                            type="checkbox"
                            id="unit1"
                          />
                          <label className="tab-label2" htmlFor="unit1">
                            Camioneta Nissan 2014
                          </label>
                          <div className="tab-content">
                            Capacidad de Carga: 1 Tonelada <br />
                            Capacidad de carga en Producto <br />
                            {/* Tabla bloc */}
                            <div className="table">
                              <div className="wrapper">
                                <center>
                                  <h1>Block</h1>
                                </center>
                                <table>
                                  <thead>
                                    <tr>
                                      <th>IBMECO</th>
                                      <th>BMRAN</th>
                                      <th>BMNCP</th>
                                      <th>BHN</th>
                                      <th>TN</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr>
                                      <td data-label="IBMNECO">16 kg</td>
                                      <td data-label="BMRAN">15 kg</td>
                                      <td data-label="BMNCP">16 kg</td>
                                      <td data-label="BHN">11 kg</td>
                                      <td data-label="TN">7 kg</td>
                                    </tr>
                                    <tr>
                                      <td data-label="IBMNECO">75 Piezas</td>
                                      <td data-label="BMRAN">75 Piezas</td>
                                      <td data-label="BMNCP">75 Piezaas</td>
                                      <td data-label="BHN">100 Piezas</td>
                                      <td data-label="TN">180 Piezas</td>
                                    </tr>
                                  </tbody>
                                </table>
                                <br />
                                <br />
                              </div>
                            </div>
                            {/* Tabla Adoquin*/}
                            <div className="table">
                              <div className="wrapper">
                                <center>
                                  <h1>Adoquin</h1>
                                </center>
                                <table>
                                  <thead>
                                    <tr>
                                      <th>ADOHEX</th>
                                      <th>ADOTAB</th>
                                      <th>ADOBET</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr>
                                      <td data-label="ADOHEX">6 kg</td>
                                      <td data-label="ADOTAB">8.5 kg</td>
                                      <td data-label="ADOBET">4.5 kg</td>
                                    </tr>
                                    <tr>
                                      <td data-label="ADOHEX">160 Piezas</td>
                                      <td data-label="ADOTAB">115 Piezas</td>
                                      <td data-label="ADOBET">150 Piezas</td>
                                    </tr>
                                  </tbody>
                                </table>
                                <br />
                                <br />
                              </div>
                            </div>
                            {/* Tabla Poste*/}
                            <div className="table">
                              <div className="wrapper">
                                <center>
                                  <h1>Poste de Concreto</h1>
                                </center>
                                <table>
                                  <thead>
                                    <tr>
                                      <th>POS2M</th>
                                      <th>POS2.5M</th>
                                      <th>POS2.5M15X15</th>
                                      <th>POS2M15X15</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr>
                                      <td data-label="POS2M">35 kg</td>
                                      <td data-label="POS2.5M">45 kg</td>
                                      <td data-label="POS2.5M15X15">-</td>
                                      <td data-label="POS2M15X15">-</td>
                                    </tr>
                                    <tr>
                                      <td data-label="POS2M">28 Piezas</td>
                                      <td data-label="POS2.5M">25 Piezas</td>
                                      <td data-label="POS2.5M15X15">-</td>
                                      <td data-label="POS2M15X15">-</td>
                                    </tr>
                                  </tbody>
                                </table>
                                <br />
                                <br />
                              </div>
                            </div>
                            {/* Tabla Tuberia*/}
                            <div className="table">
                              <div className="wrapper">
                                <center>
                                  <h1>Tubería de Concreto</h1>
                                </center>
                                <table>
                                  <thead>
                                    <tr>
                                      <th>TC6"</th>
                                      <th>TC8"</th>
                                      <th>TC10"</th>
                                      <th>TC12"</th>
                                      <th>TC15"</th>
                                      <th>TC18"</th>
                                      <th>TC24"</th>
                                      <th>BRO1x.60</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr>
                                      <td data-label="TC6'">20 kg</td>
                                      <td data-label="TC8'">38 kg</td>
                                      <td data-label="TC610'">45 kg</td>
                                      <td data-label="TC12'">56 kg</td>
                                      <td data-label="TC15'">72 kg</td>
                                      <td data-label="TC18'">113 kg</td>
                                      <td data-label="TC24'">450 kg</td>
                                      <td data-label="BRO1x.60">480 kg</td>
                                    </tr>
                                    <tr>
                                      <td data-label="TC6'">35 Piezas</td>
                                      <td data-label="TC8'">30 Piezas</td>
                                      <td data-label="TC610'">22 Piezas</td>
                                      <td data-label="TC12'">20 Piezas</td>
                                      <td data-label="TC15'">12 Piezas</td>
                                      <td data-label="TC18'">6 Piezas</td>
                                      <td data-label="TC24'">1 Piezas</td>
                                      <td data-label="BRO1x.60">2 Piezas</td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        </div>
                        {/* Ford 2014 */}
                        <div className="tab">
                          <input
                            className="inputview"
                            type="checkbox"
                            id="unit3"
                          />
                          <label className="tab-label2" htmlFor="unit3">
                            Camioneta Ford 2014
                          </label>
                          <div className="tab-content">
                            Capacidad de Carga: 3.5 Toneladas <br />
                            Capacidad de carga en Producto <br />
                            {/* Tabla bloc */}
                            <div className="table">
                              <div className="wrapper">
                                <center>
                                  <h1>Block</h1>
                                </center>
                                <table>
                                  <thead>
                                    <tr>
                                      <th>IBMECO</th>
                                      <th>BMRAN</th>
                                      <th>BMNCP</th>
                                      <th>BHN</th>
                                      <th>TN</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr>
                                      <td data-label="IBMNECO">16 kg</td>
                                      <td data-label="BMRAN">15 kg</td>
                                      <td data-label="BMNCP">16 kg</td>
                                      <td data-label="BHN">11 kg</td>
                                      <td data-label="TN">7 kg</td>
                                    </tr>
                                    <tr>
                                      <td data-label="IBMNECO">250 Piezas</td>
                                      <td data-label="BMRAN">250 Piezas</td>
                                      <td data-label="BMNCP">250 Piezaas</td>
                                      <td data-label="BHN">320 Piezas</td>
                                      <td data-label="TN">500 Piezas</td>
                                    </tr>
                                  </tbody>
                                </table>
                                <br />
                                <br />
                              </div>
                            </div>
                            {/* Tabla Adoquin*/}
                            <div className="table">
                              <div className="wrapper">
                                <center>
                                  <h1>Adoquin</h1>
                                </center>
                                <table>
                                  <thead>
                                    <tr>
                                      <th>ADOHEX</th>
                                      <th>ADOTAB</th>
                                      <th>ADOBET</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr>
                                      <td data-label="ADOHEX">6 kg</td>
                                      <td data-label="ADOTAB">8.5 kg</td>
                                      <td data-label="ADOBET">4.5 kg</td>
                                    </tr>
                                    <tr>
                                      <td data-label="ADOHEX">580 Piezas</td>
                                      <td data-label="ADOTAB">500 Piezas</td>
                                      <td data-label="ADOBET">700 Piezas</td>
                                    </tr>
                                  </tbody>
                                </table>
                                <br />
                                <br />
                              </div>
                            </div>
                            {/* Tabla Poste*/}
                            <div className="table">
                              <div className="wrapper">
                                <center>
                                  <h1>Poste de Concreto</h1>
                                </center>
                                <table>
                                  <thead>
                                    <tr>
                                      <th>POS2M</th>
                                      <th>POS2.5M</th>
                                      <th>POS2.5M15X15</th>
                                      <th>POS2M15X15</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr>
                                      <td data-label="POS2M">35 kg</td>
                                      <td data-label="POS2.5M">45 kg</td>
                                      <td data-label="POS2.5M15X15">-</td>
                                      <td data-label="POS2M15X15">-</td>
                                    </tr>
                                    <tr>
                                      <td data-label="POS2M">95 Piezas</td>
                                      <td data-label="POS2.5M">85 Piezas</td>
                                      <td data-label="POS2.5M15X15">-</td>
                                      <td data-label="POS2M15X15">-</td>
                                    </tr>
                                  </tbody>
                                </table>
                                <br />
                                <br />
                              </div>
                            </div>
                            {/* Tabla Tuberia*/}
                            <div className="table">
                              <div className="wrapper">
                                <center>
                                  <h1>Tubería de Concreto</h1>
                                </center>
                                <table>
                                  <thead>
                                    <tr>
                                      <th>TC6"</th>
                                      <th>TC8"</th>
                                      <th>TC10"</th>
                                      <th>TC12"</th>
                                      <th>TC15"</th>
                                      <th>TC18"</th>
                                      <th>TC24"</th>
                                      <th>BRO1x.60</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr>
                                      <td data-label="TC6'">20 kg</td>
                                      <td data-label="TC8'">38 kg</td>
                                      <td data-label="TC610'">45 kg</td>
                                      <td data-label="TC12'">56 kg</td>
                                      <td data-label="TC15'">72 kg</td>
                                      <td data-label="TC18'">113 kg</td>
                                      <td data-label="TC24'">450 kg</td>
                                      <td data-label="BRO1x.60">480 kg</td>
                                    </tr>
                                    <tr>
                                      <td data-label="TC6'">100 Piezas</td>
                                      <td data-label="TC8'">70 Piezas</td>
                                      <td data-label="TC610'">62 Piezas</td>
                                      <td data-label="TC12'">50 Piezas</td>
                                      <td data-label="TC15'">24 Piezas</td>
                                      <td data-label="TC18'">15 Piezas</td>
                                      <td data-label="TC24'">5 Piezas</td>
                                      <td data-label="BRO1x.60">10 Piezas</td>
                                    </tr>
                                  </tbody>
                                </table>
                                <br />
                                <br />
                              </div>
                            </div>
                          </div>
                        </div>
                        {/* Camion Inter */}
                        <div className="tab">
                          <input
                            className="inputview"
                            type="checkbox"
                            id="unit4"
                          />
                          <label className="tab-label2" htmlFor="unit4">
                            Camión International
                          </label>
                          <div className="tab-content">
                            Capacidad de Carga: 10 Toneladas <br />
                            Capacidad de carga en Producto <br />
                            {/* Tabla bloc */}
                            <div className="table">
                              <div className="wrapper">
                                <center>
                                  <h1>Block</h1>
                                </center>
                                <table>
                                  <thead>
                                    <tr>
                                      <th>IBMECO</th>
                                      <th>BMRAN</th>
                                      <th>BMNCP</th>
                                      <th>BHN</th>
                                      <th>TN</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr>
                                      <td data-label="IBMNECO">16 kg</td>
                                      <td data-label="BMRAN">15 kg</td>
                                      <td data-label="BMNCP">16 kg</td>
                                      <td data-label="BHN">11 kg</td>
                                      <td data-label="TN">7 kg</td>
                                    </tr>
                                    <tr>
                                      <td data-label="IBMNECO">600 Piezas</td>
                                      <td data-label="BMRAN">600 Piezas</td>
                                      <td data-label="BMNCP">600 Piezaas</td>
                                      <td data-label="BHN">1000 Piezas</td>
                                      <td data-label="TN">1500 Piezas</td>
                                    </tr>
                                  </tbody>
                                </table>
                                <br />
                                <br />
                              </div>
                            </div>
                            {/* Tabla Adoquin*/}
                            <div className="table">
                              <div className="wrapper">
                                <center>
                                  <h1>Adoquin</h1>
                                </center>
                                <table>
                                  <thead>
                                    <tr>
                                      <th>ADOHEX</th>
                                      <th>ADOTAB</th>
                                      <th>ADOBET</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr>
                                      <td data-label="ADOHEX">6 kg</td>
                                      <td data-label="ADOTAB">8.5 kg</td>
                                      <td data-label="ADOBET">4.5 kg</td>
                                    </tr>
                                    <tr>
                                      <td data-label="ADOHEX">1500 Piezas</td>
                                      <td data-label="ADOTAB">1000 Piezas</td>
                                      <td data-label="ADOBET">2000 Piezas</td>
                                    </tr>
                                  </tbody>
                                </table>
                                <br />
                                <br />
                              </div>
                            </div>
                            {/* Tabla Poste*/}
                            <div className="table">
                              <div className="wrapper">
                                <center>
                                  <h1>Poste de Concreto</h1>
                                </center>
                                <table>
                                  <thead>
                                    <tr>
                                      <th>POS2M</th>
                                      <th>POS2.5M</th>
                                      <th>POS2.5M15X15</th>
                                      <th>POS2M15X15</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr>
                                      <td data-label="POS2M">35 kg</td>
                                      <td data-label="POS2.5M">45 kg</td>
                                      <td data-label="POS2.5M15X15">-</td>
                                      <td data-label="POS2M15X15">-</td>
                                    </tr>
                                    <tr>
                                      <td data-label="POS2M">280 Piezas</td>
                                      <td data-label="POS2.5M">218 Piezas</td>
                                      <td data-label="POS2.5M15X15">-</td>
                                      <td data-label="POS2M15X15">-</td>
                                    </tr>
                                  </tbody>
                                </table>
                                <br />
                                <br />
                              </div>
                            </div>
                            {/* Tabla Tuberia*/}
                            <div className="table">
                              <div className="wrapper">
                                <center>
                                  <h1>Tubería de Concreto</h1>
                                </center>
                                <table>
                                  <thead>
                                    <tr>
                                      <th>TC6"</th>
                                      <th>TC8"</th>
                                      <th>TC10"</th>
                                      <th>TC12"</th>
                                      <th>TC15"</th>
                                      <th>TC18"</th>
                                      <th>TC24"</th>
                                      <th>BRO1x.60</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr>
                                      <td data-label="TC6'">20 kg</td>
                                      <td data-label="TC8'">38 kg</td>
                                      <td data-label="TC610'">45 kg</td>
                                      <td data-label="TC12'">56 kg</td>
                                      <td data-label="TC15'">72 kg</td>
                                      <td data-label="TC18'">113 kg</td>
                                      <td data-label="TC24'">450 kg</td>
                                      <td data-label="BRO1x.60">480 kg</td>
                                    </tr>
                                    <tr>
                                      <td data-label="TC6'">320 Piezas</td>
                                      <td data-label="TC8'">30210 Piezas</td>
                                      <td data-label="TC610'">150 Piezas</td>
                                      <td data-label="TC12'">100 Piezas</td>
                                      <td data-label="TC15'">75 Piezas</td>
                                      <td data-label="TC18'">70 Piezas</td>
                                      <td data-label="TC24'">24 Piezas</td>
                                      <td data-label="BRO1x.60">24 Piezas</td>
                                    </tr>
                                  </tbody>
                                </table>
                                <br />
                                <br />
                              </div>
                            </div>
                          </div>
                        </div>
                        {/* Camion Ford */}
                        <div className="tab">
                          <input
                            className="inputview"
                            type="checkbox"
                            id="unit5"
                          />
                          <label className="tab-label2" htmlFor="unit5">
                            Camión Ford
                          </label>
                          <div className="tab-content">
                            Capacidad de Carga: 10 Toneladas <br />
                            Capacidad de carga en Producto <br />
                            {/* Tabla bloc */}
                            <div className="table">
                              <div className="wrapper">
                                <center>
                                  <h1>Block</h1>
                                </center>
                                <table>
                                  <thead>
                                    <tr>
                                      <th>IBMECO</th>
                                      <th>BMRAN</th>
                                      <th>BMNCP</th>
                                      <th>BHN</th>
                                      <th>TN</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr>
                                      <td data-label="IBMNECO">16 kg</td>
                                      <td data-label="BMRAN">15 kg</td>
                                      <td data-label="BMNCP">16 kg</td>
                                      <td data-label="BHN">11 kg</td>
                                      <td data-label="TN">7 kg</td>
                                    </tr>
                                    <tr>
                                      <td data-label="IBMNECO">600 Piezas</td>
                                      <td data-label="BMRAN">600 Piezas</td>
                                      <td data-label="BMNCP">600 Piezaas</td>
                                      <td data-label="BHN">1000 Piezas</td>
                                      <td data-label="TN">1500 Piezas</td>
                                    </tr>
                                  </tbody>
                                </table>
                                <br />
                                <br />
                              </div>
                            </div>
                            {/* Tabla Adoquin*/}
                            <div className="table">
                              <div className="wrapper">
                                <center>
                                  <h1>Adoquin</h1>
                                </center>
                                <table>
                                  <thead>
                                    <tr>
                                      <th>ADOHEX</th>
                                      <th>ADOTAB</th>
                                      <th>ADOBET</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr>
                                      <td data-label="ADOHEX">6 kg</td>
                                      <td data-label="ADOTAB">8.5 kg</td>
                                      <td data-label="ADOBET">4.5 kg</td>
                                    </tr>
                                    <tr>
                                      <td data-label="ADOHEX">1500 Piezas</td>
                                      <td data-label="ADOTAB">1000 Piezas</td>
                                      <td data-label="ADOBET">2000 Piezas</td>
                                    </tr>
                                  </tbody>
                                </table>
                                <br />
                                <br />
                              </div>
                            </div>
                            {/* Tabla Poste*/}
                            <div className="table">
                              <div className="wrapper">
                                <center>
                                  <h1>Poste de Concreto</h1>
                                </center>
                                <table>
                                  <thead>
                                    <tr>
                                      <th>POS2M</th>
                                      <th>POS2.5M</th>
                                      <th>POS2.5M15X15</th>
                                      <th>POS2M15X15</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr>
                                      <td data-label="POS2M">35 kg</td>
                                      <td data-label="POS2.5M">45 kg</td>
                                      <td data-label="POS2.5M15X15">-</td>
                                      <td data-label="POS2M15X15">-</td>
                                    </tr>
                                    <tr>
                                      <td data-label="POS2M">35 Piezas</td>
                                      <td data-label="POS2.5M">45 Piezas</td>
                                      <td data-label="POS2.5M15X15">-</td>
                                      <td data-label="POS2M15X15">-</td>
                                    </tr>
                                  </tbody>
                                </table>
                                <br />
                                <br />
                              </div>
                            </div>
                            {/* Tabla Tuberia*/}
                            <div className="table">
                              <div className="wrapper">
                                <center>
                                  <h1>Tubería de Concreto</h1>
                                  En la tuberia de concreto la capacidad se
                                  determina en base al espacio y no al peso.
                                </center>
                                <table>
                                  <thead>
                                    <tr>
                                      <th>TC6"</th>
                                      <th>TC8"</th>
                                      <th>TC10"</th>
                                      <th>TC12"</th>
                                      <th>TC15"</th>
                                      <th>TC18"</th>
                                      <th>TC24"</th>
                                      <th>BRO1x.60</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr>
                                      <td data-label="TC6'">20 kg</td>
                                      <td data-label="TC8'">38 kg</td>
                                      <td data-label="TC610'">45 kg</td>
                                      <td data-label="TC12'">56 kg</td>
                                      <td data-label="TC15'">72 kg</td>
                                      <td data-label="TC18'">113 kg</td>
                                      <td data-label="TC24'">450 kg</td>
                                      <td data-label="BRO1x.60">480 kg</td>
                                    </tr>
                                    <tr>
                                      <td data-label="TC6'">260 Piezas</td>
                                      <td data-label="TC8'">170 Piezas</td>
                                      <td data-label="TC610'">124 Piezas</td>
                                      <td data-label="TC12'">80 Piezas</td>
                                      <td data-label="TC15'">70 Piezas</td>
                                      <td data-label="TC18'">50 Piezas</td>
                                      <td data-label="TC24'">20 Piezas</td>
                                      <td data-label="BRO1x.60">20 Piezas</td>
                                    </tr>
                                  </tbody>
                                </table>
                                <br />
                                <br />
                              </div>
                            </div>
                          </div>
                        </div>
                        {/* Camion Kenworth */}
                        <div className="tab">
                          <input
                            className="inputview"
                            type="checkbox"
                            id="unit6"
                          />
                          <label className="tab-label2" htmlFor="unit6">
                            Camión Kenworth
                          </label>
                          <div className="tab-content">
                            Capacidad de Carga: 20 Toneladas <br />
                            Capacidad de carga en Producto <br />
                            {/* Tabla bloc */}
                            <div className="table">
                              <div className="wrapper">
                                <center>
                                  <h1>Block</h1>
                                </center>
                                <table>
                                  <thead>
                                    <tr>
                                      <th>IBMECO</th>
                                      <th>BMRAN</th>
                                      <th>BMNCP</th>
                                      <th>BHN</th>
                                      <th>TN</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr>
                                      <td data-label="IBMNECO">16 kg</td>
                                      <td data-label="BMRAN">15 kg</td>
                                      <td data-label="BMNCP">16 kg</td>
                                      <td data-label="BHN">11 kg</td>
                                      <td data-label="TN">7 kg</td>
                                    </tr>
                                    <tr>
                                      <td data-label="IBMNECO">1200 Piezas</td>
                                      <td data-label="BMRAN">1200 Piezas</td>
                                      <td data-label="BMNCP">1200 Piezaas</td>
                                      <td data-label="BHN">1500 Piezas</td>
                                      <td data-label="TN">2000 Piezas</td>
                                    </tr>
                                  </tbody>
                                </table>
                                <br />
                                <br />
                              </div>
                            </div>
                            {/* Tabla Adoquin*/}
                            <div className="table">
                              <div className="wrapper">
                                <center>
                                  <h1>Adoquin</h1>
                                </center>
                                <table>
                                  <thead>
                                    <tr>
                                      <th>ADOHEX</th>
                                      <th>ADOTAB</th>
                                      <th>ADOBET</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr>
                                      <td data-label="ADOHEX">6 kg</td>
                                      <td data-label="ADOTAB">8.5 kg</td>
                                      <td data-label="ADOBET">4.5 kg</td>
                                    </tr>
                                    <tr>
                                      <td data-label="ADOHEX">- Piezas</td>
                                      <td data-label="ADOTAB">- Piezas</td>
                                      <td data-label="ADOBET">- Piezas</td>
                                    </tr>
                                  </tbody>
                                </table>
                                <br />
                                <br />
                              </div>
                            </div>
                            {/* Tabla Poste*/}
                            <div className="table">
                              <div className="wrapper">
                                <center>
                                  <h1>Poste de Concreto</h1>
                                </center>
                                <table>
                                  <thead>
                                    <tr>
                                      <th>POS2M</th>
                                      <th>POS2.5M</th>
                                      <th>POS2.5M15X15</th>
                                      <th>POS2M15X15</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr>
                                      <td data-label="POS2M">35 kg</td>
                                      <td data-label="POS2.5M">45 kg</td>
                                      <td data-label="POS2.5M15X15">-</td>
                                      <td data-label="POS2M15X15">-</td>
                                    </tr>
                                    <tr>
                                      <td data-label="POS2M">- Piezas</td>
                                      <td data-label="POS2.5M">- Piezas</td>
                                      <td data-label="POS2.5M15X15">-</td>
                                      <td data-label="POS2M15X15">-</td>
                                    </tr>
                                  </tbody>
                                </table>
                                <br />
                                <br />
                              </div>
                            </div>
                            {/* Tabla Tuberia*/}
                            <div className="table">
                              <div className="wrapper">
                                <center>
                                  <h1>Tubería de Concreto</h1>
                                </center>
                                <table>
                                  <thead>
                                    <tr>
                                      <th>TC6"</th>
                                      <th>TC8"</th>
                                      <th>TC10"</th>
                                      <th>TC12"</th>
                                      <th>TC15"</th>
                                      <th>TC18"</th>
                                      <th>TC24"</th>
                                      <th>BRO1x.60</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr>
                                      <td data-label="TC6'">20 kg</td>
                                      <td data-label="TC8'">38 kg</td>
                                      <td data-label="TC610'">45 kg</td>
                                      <td data-label="TC12'">56 kg</td>
                                      <td data-label="TC15'">72 kg</td>
                                      <td data-label="TC18'">113 kg</td>
                                      <td data-label="TC24'">450 kg</td>
                                      <td data-label="BRO1x.60">480 kg</td>
                                    </tr>
                                    <tr>
                                      <td data-label="TC6'">- Piezas</td>
                                      <td data-label="TC8'">- Piezas</td>
                                      <td data-label="TC610'">- Piezas</td>
                                      <td data-label="TC12'">- Piezas</td>
                                      <td data-label="TC15'">- Piezas</td>
                                      <td data-label="TC18'">- Piezas</td>
                                      <td data-label="TC24'">- Piezas</td>
                                      <td data-label="BRO1x.60">2- Piezas</td>
                                    </tr>
                                  </tbody>
                                </table>
                                <br />
                                <br />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* Condiciones de Envio */}
              <div className="tab">
                <input className="inputview" type="checkbox" id="chck2" />
                <label className="tab-label" htmlFor="chck2">
                  Condiciones de Envío
                </label>
                <div className="tab-text">
                  ⚪ Para los Prefabricados, las condiciones para mandar
                  vehiculos a su maxima capacidad son: <br /> <br />
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; ⚪ Caminos Pavimentados. <br />
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; ⚪ Terracerias Planas. <br />
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; ⚪ No Subidas Ni Bajadas
                  Pronunciadas. <br /> <br />
                  ⚪ Si se reunen estas condiciones se mandan los viajes sin
                  exponer los vehiculos.
                  <br /> <br />⚪ En la tuberia de concreto la capacidad se
                  determina en base al espacio y no al peso.
                </div>
              </div>
              {/* Mapa */}
              <div className="tab">
                <input className="inputview" type="checkbox" id="chck3" />
                <label className="tab-label" htmlFor="chck3">
                  Mapa
                </label>
                <div className="tab-text">
                <iframe title='Zonas de Entrega' src="https://www.google.com/maps/d/embed?mid=1rS3GXXbSXDqGPdCf-8AFb9Hqs4fkwhM&ehbc=2E312F" width="100%" height="600"></iframe>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
