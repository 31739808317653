export const fecha = () => {
  const date = new Date()

  const format =
    date.getDate() + '/' + (date.getMonth() + 1) + '/' + date.getFullYear()

  return format
}

export const horaTicket = () => {
  const date = new Date()

  var hours = date.getHours()
  var minutes = date.getMinutes()
  var seconds = date.getSeconds()

  var format = ''

  if (hours < 10) {
    hours = '0' + hours
  }

  if (minutes < 10) {
    minutes = '0' + minutes
  }

  if (seconds < 10) {
    seconds = '0' + seconds
  }

  if (hours > 12) {
    hours = hours - 12
    if (hours < 10) {
      hours = '0' + hours
    }

    format = hours + ':' + minutes + ':' + seconds + ' PM'
  } else {
    format = hours + ':' + minutes + ':' + seconds + ' AM'
  }

  return format
}

export const formatTime = (time) => {
  var hours = time.getHours()
  var minutes = time.getMinutes()
  var formattedTime = ''

  if (hours < 10) {
    hours = '0' + hours
  }

  if (minutes < 10) {
    minutes = '0' + minutes
  }

  if (hours > 12) {
    hours = hours - 12
    if (hours < 10) {
      hours = '0' + hours
    }

    formattedTime = hours + ':' + minutes + ' PM'
  } else {
    if (hours === 12) {
      formattedTime = hours + ':' + minutes + ' PM'
    } else {
      formattedTime = hours + ':' + minutes + ' AM'
    }
  }

  return formattedTime
}

export const formatDate = (date) => {
  const format =
    date.getDate() + '/' + (date.getMonth() + 1) + '/' + date.getFullYear()

  return format
}

export const msToTime = (time) => {
  let hours = time / (1000 * 60 * 60)
  let absoluteHours = Math.floor(hours)
  let h = absoluteHours > 9 ? absoluteHours : '0' + absoluteHours

  let minutes = (hours - absoluteHours) * 60
  let absoluteMinutes = Math.floor(minutes)
  let m = absoluteMinutes > 9 ? absoluteMinutes : '0' + absoluteMinutes

  return h + ':' + m
}
