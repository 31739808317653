export const zonas = [
  { id: '', title: 'Selecciona una Zona' },
  { id: 'Local 3KM', title: 'Local 3KM' },
  { id: 'Local 12KM', title: 'Local 12KM' },
  { id: 'Janamoro', title: 'Janamoro' },
  { id: 'Los Pozos', title: 'Los Pozos' },
  { id: 'La Veronica', title: 'La Veronica' },
  { id: 'San Matías', title: 'San Matías' },
  { id: 'San Pedro', title: 'San Pedro' },
  { id: 'Salida a Morelia', title: 'Salida a Morelia' },
  { id: 'Puerto de Cuitareo', title: 'Puerto de Cuitareo' },
  { id: 'El Potrero', title: 'El Potrero' },
  { id: 'Huaniqueo', title: 'Huaniqueo' },
  { id: 'Santa Rosa', title: 'Santa Rosa' },
  { id: 'San Lorenzo', title: 'San Lorenzo' },
  { id: 'Las Grutas', title: 'Las Grutas' },
  { id: 'Turundeo', title: 'Turundeo' },
  { id: 'Huanimoro', title: 'Huanimoro' },
  { id: 'Tuxpan', title: 'Tuxpan' },
  { id: 'La Soledad', title: 'La Soledad' },
  { id: 'Jungapeo', title: 'Jungapeo' },
  { id: 'Laguna Verde', title: 'Laguna Verde' },
  { id: 'Ocampo', title: 'Ocampo' },
  { id: 'El Rosario', title: 'El Rosario' },
  { id: 'Angangueo', title: 'Angangueo' },
  { id: 'Colonia Independencia', title: 'Colonia Independencia' },
  { id: 'Rincon de Soto', title: 'Rincon de Soto' },
  { id: 'Aporo', title: 'Aporo' },
  { id: 'San Miguel La Virgen', title: 'San Miguel La Virgen' },
  { id: 'Irimbo', title: 'Irimbo' },
  { id: 'Los Marzos', title: 'Los Marzos' },
  { id: 'Tarimoro', title: 'Tarimoro' },
  { id: 'Senguio', title: 'Senguio' },
  { id: 'Tzintzingareo', title: 'Tzintzingareo' },
  { id: 'Maravatío', title: 'Maravatío' },
  { id: 'Tacario', title: 'Tacario' },
  { id: 'Casas Pintas', title: 'Casas Pintas' },
  { id: 'Mesa de Guadalupe', title: 'Mesa de Guadalupe' },
  { id: 'Los Azufres', title: 'Los Azufres' },
  { id: 'Cieneguillas', title: 'Cieneguillas' },
  { id: 'Huajumbaro', title: 'Huajumbaro' },
  { id: 'San Antonio Villalonguin', title: 'San Antonio Villalonguin' },
  { id: 'Morelos', title: 'Morelos' },
  { id: 'Agostitlan', title: 'Agostitlan' },
  { id: 'Chaparro', title: 'Chaparro' },
  { id: 'El Cortijo', title: 'El Cortijo' },
  { id: 'San Bartolo', title: 'San Bartolo' },
  { id: 'Pucuato', title: 'Pucuato' },
  { id: 'La Venta', title: 'La Venta' },
  { id: 'San Jeronimo', title: 'San Jeronimo' },
  { id: 'Las Pilas', title: 'Las Pilas' },
  { id: 'San Lucas', title: 'San Lucas' },
  { id: 'La Zarzamora', title: 'La Zarzamora' },
  { id: 'San Felipe', title: 'San Felipe' },
  { id: 'Chincua', title: 'Chincua' },
  { id: 'Cruz de Caminos', title: 'Cruz de Caminos' },
  { id: 'Puente de Tierra', title: 'Puente de Tierra' },
]
