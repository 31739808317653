export const productos = [
  {
    id: 'CPC-MORT',
    title: 'CPC-MORT',
  },
  {
    id: 'Block Macizo',
    title: 'Block Macizo',
  },
  {
    id: 'Block Hueco',
    title: 'Block Hueco',
  },
  {
    id: 'Tabicon',
    title: 'Tabicon',
  },
  {
    id: 'Adoquin Hex',
    title: 'Adoquin Hex',
  },
  {
    id: 'Adoquin Tab',
    title: 'Adoquin Tab',
  },
  {
    id: 'Adoquin Bet',
    title: 'Adoquin Bet',
  },
  {
    id: 'Poste 2x10x10',
    title: 'Poste 2x10x10',
  },
  {
    id: 'Poste 2.5x10x10',
    title: 'Poste 2.5x10x10',
  },
  {
    id: 'Poste 2x15x15',
    title: 'Poste 2x15x15',
  },
  {
    id: 'Poste 2.5x15x15',
    title: 'Poste 2.5x15x15',
  },
  {
    id: 'TC6',
    title: 'TC6',
  },
  {
    id: 'TC8',
    title: 'TC8',
  },
  {
    id: 'TC10',
    title: 'TC10',
  },
  {
    id: 'TC12',
    title: 'TC12',
  },
  {
    id: 'TC15',
    title: 'TC15',
  },
  {
    id: 'TC18',
    title: 'TC18',
  },
  {
    id: 'TC24',
    title: 'TC24',
  },
  {
    id: 'Brocal',
    title: 'Brocal',
  },
]
