import { useState } from 'react'
import {
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
} from '@mui/material'
import { makeStyles } from '@material-ui/styles'
import Table from '@mui/material/Table'

const useStyles = makeStyles({
  table: {
    border: '1px solid #ccc',
    borderCollapse: 'collapse',
    margin: '0',
    padding: '0',
    width: '100%',
    tableLayout: 'fixed',
    color: '#00544e',

    '& thead th': {
      fontSize: '0.8em',
      letterSpacing: '0.1em',
      textTransform: 'uppercase',
      background: '#dec65e',
      color: '#00544e',
    },
    '& tbody td': {
      fontWeight: '300',
    },
    '& tbody tr:hover': {
      backgroundColor: '#e9e9e9',
      cursor: 'pointer',
    },
  },
  '@media (max-width: 800px)': {
    table: {
      border: '0',
      width: '100%',
      marginTop: '5%',
      '& thead th': {
        border: 'none',
        clip: 'rect(0 0 0 0)',
        height: '1px',
        margin: '-1px',
        overflow: 'hidden',
        padding: '0',
        position: 'absolute',
        width: '1px',
      },

      '& tbody tr': {
        borderBottom: '3px solid #ddd',
        display: 'block',
        marginBottom: '0.625em',
      },

      '& tbody td': {
        borderBottom: '1px solid #ddd',
        display: 'block',
        fontSize: '0.8em',
        textAlign: 'right',
      },

      '& tbody td::before': {
        content: 'attr(data-label)',
        float: 'left',
        fontWeight: 'bold',
        textTransform: 'uppercase',
      },

      '& tbody td:last-child': {
        borderBottom: '0',
      },
    },
  },

})

export default function useTable(users, headCells, filterFn) {
  const classes = useStyles()

  const pages = [5, 10, 25]
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(pages[page])
  const [order, setOrder] = useState()
  const [orderBy, setOrderBy] = useState()

  const TblContainer = (props) => (
    <Table className={classes.table}>{props.children}</Table>
  )

  const TblHead = (props) => {
    const handleSortRequest = (cellId) => {
      const isAsc = orderBy === cellId && order === 'asc'
      setOrder(isAsc ? 'desc' : 'asc')
      setOrderBy(cellId)
    }
    return (
      <TableHead>
        <TableRow>
          {headCells.map((headCell) => (
            <TableCell
              key={headCell.id}
              sortDirection={orderBy === headCell.id ? order : false}
            >
              {headCell.disableSorting ? (
                headCell.label
              ) : (
                <TableSortLabel
                  active={orderBy === headCell.id}
                  direction={orderBy === headCell.id ? order : 'asc'}
                  onClick={() => {
                    handleSortRequest(headCell.id)
                  }}
                >
                  {headCell.label}
                </TableSortLabel>
              )}
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    )
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10))
  }

  const TblPagination = () => (
    <TablePagination
      component="div"
      page={page}
      rowsPerPageOptions={pages}
      rowsPerPage={rowsPerPage}
      count={users.length}
      onPageChange={handleChangePage}
      onRowsPerPageChange={handleChangeRowsPerPage}
    />
  )

  function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index])
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0])
      if (order !== 0) return order
      return a[1] - b[1]
    })
    return stabilizedThis.map((el) => el[0])
  }

  function getComparator(order, orderBy) {
    return order === 'desc'
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy)
  }

  function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1
    }
    if (b[orderBy] > a[orderBy]) {
      return 1
    }
    return 0
  }

  const recordAfterPagingAndSorting = () => {
    return stableSort(filterFn.fn(users), getComparator(order, orderBy)).slice(
      page * rowsPerPage,
      (page + 1) * rowsPerPage
    )
  }

  return {
    TblContainer,
    TblHead,
    TblPagination,
    recordAfterPagingAndSorting,
  }
}
