import { TextField } from '@mui/material'

export const Input = (props) => {
  const { name, type, value, error = null, onChange, ...other } = props
  return (
    <TextField
      sx={{
        backgroundColor: '#e9e9e9',
        borderRadius: '20px',
        height: '38px',
        border: '1px solid rgba(201, 201, 201, 0.711)',
        paddingLeft: '20px',
        paddingRight: '10px',
        paddingTop: '5px',
      }}
      type={type}
      variant="standard"
      autoComplete="off"
      name={name}
      value={value}
      onChange={onChange}
      InputProps={{
        disableUnderline: true,
        style: {
            fontSize: '14px',
            fontFamily: 'Nunito'
        }
      }}
      {...other}
      {...(error && { error: true, helperText: error })}
    />
  )
}
