import { useEffect, useState } from 'react'
import Controls from '../UI/Controls'
import { Form } from '../UI/Form'
import { IconButton } from '@mui/material'
import { Clear } from '@mui/icons-material'

export const AddProducts = ({
  product,
  setProduct,
  anotaciones,
  setAnotaciones,
  setOpenPopup,
}) => {
  // Función para descomponer el valor del parámetro 'product' (si está presente) en un array de objetos
  const parseProducts = (productString) => {
    if (!productString) return [{ product: '', quantity: '' }]

    return productString.split(', ').map((item) => {
      const [quantity, ...productArr] = item.split(' ')
      return { product: productArr.join(' '), quantity }
    })
  }

  const [products, setProducts] = useState(parseProducts(product))

  const productosFaltantes = [
    { id: 'CPC30R', title: 'CPC30R' },
    { id: 'MORT', title: 'MORT' },
    { id: 'VAR3/8', title: 'VAR3/8' },
    { id: 'Block Liso Natural', title: 'Block Liso Natural' },
    { id: 'Block Liso Rosa', title: 'Block Liso Rosa' },
    { id: 'Block Liso Amarillo', title: 'Block Liso Amarillo' },
    { id: 'Block Liso Ladrillo', title: 'Block Liso Ladrillo' },
    { id: 'Block Liso Cafe Oscuro', title: 'Block Liso Cafe Oscuro' },
    { id: 'Block Liso Negro', title: 'Block Liso Negro' },
    { id: 'Block Ranurado', title: 'Block Ranurado' },
    {
      id: 'Block Cara de Piedra Natural',
      title: 'Block Cara de Piedra Liso Natural',
    },
    {
      id: 'Block Cara de Piedra Rosa',
      title: 'Block Cara de Piedra Liso Rosa',
    },
    {
      id: 'Block Cara de Piedra Amarillo',
      title: 'Block Cara de Piedra Liso Amarillo',
    },
    {
      id: 'Block Cara de Piedra Ladrillo',
      title: 'Block Cara de Piedra Liso Ladrillo',
    },
    {
      id: 'Block Cara de Piedra Cafe Oscuro',
      title: 'Block Cara de Piedra Liso Cafe Oscuro',
    },
    {
      id: 'Block Cara de Piedra Negro',
      title: 'Block Cara de Piedra Liso Negro',
    },
    {
      id: 'Block Hueco',
      title: 'Block Hueco',
    },
    {
      id: 'Tabicon',
      title: 'Tabicon',
    },
    {
      id: 'Adoquin Hexagonal Natural',
      title: 'Adoquin Hexagonal Natural',
    },
    {
      id: 'Adoquin Hexagonal Rosa',
      title: 'Adoquin Hexagonal Rosa',
    },
    {
      id: 'Adoquin Hexagonal Amarillo',
      title: 'Adoquin Hexagonal Amarillo',
    },
    {
      id: 'Adoquin Hexagonal Ladrillo',
      title: 'Adoquin Hexagonal Ladrillo',
    },
    {
      id: 'Adoquin Hexagonal Cafe Oscuro',
      title: 'Adoquin Hexagonal Cafe Oscuro',
    },
    {
      id: 'Adoquin Hexagonal Negro',
      title: 'Adoquin Hexagonal Negro',
    },
    {
      id: 'Adoquin Tabasco Natural',
      title: 'Adoquin Tabasco Natural',
    },
    {
      id: 'Adoquin Tabasco Rosa',
      title: 'Adoquin Tabasco Rosa',
    },
    {
      id: 'Adoquin Tabasco Amarillo',
      title: 'Adoquin Tabasco Amarillo',
    },
    {
      id: 'Adoquin Tabasco Ladrillo',
      title: 'Adoquin Tabasco Ladrillo',
    },
    {
      id: 'Adoquin Tabasco Cafe Oscuro',
      title: 'Adoquin Tabasco Cafe Oscuro',
    },
    {
      id: 'Adoquin Tabasco Negro',
      title: 'Adoquin Tabasco Negro',
    },
    {
      id: 'Adoquin Betone Natural',
      title: 'Adoquin Betone Natural',
    },
    {
      id: 'Adoquin Betone Rosa',
      title: 'Adoquin Betone Rosa',
    },
    {
      id: 'Adoquin Betone Amarillo',
      title: 'Adoquin Betone Amarillo',
    },
    {
      id: 'Adoquin Betone Ladrillo',
      title: 'Adoquin Betone Ladrillo',
    },
    {
      id: 'Adoquin Betone Cafe Oscuro',
      title: 'Adoquin Betone Cafe Oscuro',
    },
    {
      id: 'Adoquin Betone Negro',
      title: 'Adoquin Betone Negro',
    },
    {
      id: 'Poste 2x10x10',
      title: 'Poste 2x10x10',
    },
    {
      id: 'Poste 2.5x10x10',
      title: 'Poste 2.5x10x10',
    },
    {
      id: 'Poste 2x15x15',
      title: 'Poste 2x15x15',
    },
    {
      id: 'Poste 2.5x15x15',
      title: 'Poste 2.5x15x15',
    },
    {
      id: 'TC6',
      title: 'TC6',
    },
    {
      id: 'TC8',
      title: 'TC8',
    },
    {
      id: 'TC10',
      title: 'TC10',
    },
    {
      id: 'TC12',
      title: 'TC12',
    },
    {
      id: 'TC15',
      title: 'TC15',
    },
    {
      id: 'TC18',
      title: 'TC18',
    },
    {
      id: 'TC24',
      title: 'TC24',
    },
    {
      id: 'Brocal',
      title: 'Brocal',
    },
  ]

  const handleSubmit = (e) => {
    e.preventDefault()
    const concatenatedProducts = products
      .map((item) => `${item.quantity} ${item.product}`)
      .join(', ')

    setProduct(concatenatedProducts)
    setOpenPopup(false)
  }

  const handleInputChange = (index, field, value) => {
    const newProducts = [...products]
    newProducts[index][field] = value
    setProducts(newProducts)
  }

  const addProductRow = () => {
    setProducts([...products, { product: '', quantity: '' }])
  }

  const removeProductRow = (index) => {
    const newProducts = products.filter((_, i) => i !== index) // Filtrar el producto que quieres eliminar
    setProducts(newProducts)
  }

  return (
    <div
      style={{
        justifyContent: 'center',
        display: 'flex',
        marginBottom: '10px',
        flexDirection: 'column',
      }}
    >
      <Form onSubmit={handleSubmit}>
        {products.map((item, index) => (
          <div className="large-group" style={{ marginTop: '10px' }}>
            <div
              className="medium-group"
              style={{ flex: 1, marginRight: '10px' }}
            >
              <label htmlFor={`product-${index}`}>Producto {index + 1}</label>
              <Controls.Select
                id={`product-${index}`}
                value={item.product}
                onChange={(e) =>
                  handleInputChange(index, 'product', e.target.value)
                }
                options={productosFaltantes}
              ></Controls.Select>
            </div>
            <div className="medium-group" style={{ flex: 1 }}>
              <label htmlFor={`quantity-${index}`}>Cantidad {index + 1}</label>
              <Controls.Input
                id={`quantity-${index}`}
                value={item.quantity}
                onChange={(e) =>
                  handleInputChange(index, 'quantity', e.target.value)
                }
              />
            </div>

            <IconButton
              onClick={() => removeProductRow(index)}
              style={{
                marginTop: '22px',
                marginLeft: '10px',
                backgroundColor: '#00544e',
                color: 'white',
                height: '38px',
                width: '38px',
                '&:hover': {
                  backgroundColor: '#009696',
                  border: '1px solid #00544e',
                },
              }}
            >
              <Clear />
            </IconButton>
          </div>
        ))}
        <div className="textarea-div" style={{ marginTop: '20px' }}>
          <label htmlFor="anotaciones">Anotaciones</label>
          <Controls.TextArea
            className="input-select"
            id="anotaciones"
            type="text"
            name="anotaciones"
            value={anotaciones}
            onChange={(event) => {
              setAnotaciones(event.target.value)
            }}
            placeholder="Anotaciones del pedido, productos que no aparezcan en la lista de arriba, número de complemento, orden de salida del día"
          />
        </div>
        <div className="large-group" style={{ marginTop: '10px' }}>
          <div className="medium-group">
            <button className="btn" type="button" onClick={addProductRow}>
              Agregar Producto
            </button>
          </div>
          <div className="medium-group">
            <button className="btn" onClick={handleSubmit}>
              Enviar
            </button>
          </div>
        </div>
      </Form>
    </div>
  )
}
