import React, { useEffect, useState } from 'react'

import { useForm, Form } from '../UI/Form'
import Controls from '../UI/Controls'

import {
  calculateService,
  totalMinimo,
  totalInter,
  totalMaximo,
  totalRecomendado,
  addService,
} from '../../services/services'

import { productos } from './products'
import { unidades } from './array'

import Swal from 'sweetalert2'
import { Close, LockOpen, OpenInBrowser } from '@mui/icons-material'
import { Drawer, IconButton, Tooltip } from '@mui/material'
import { CustomDialog } from '../UI/Dialog'
import { ClientsSelect } from './client'
import { makeStyles } from '@material-ui/core'
import { PlaceSelect } from './place'

const initialValues = {
  lugar: '',
  cliente: '',
  referencia: '',
  unidad1: '',
  salidas1: '',
  producto1: '',
  cantidad1: '',
  unidad2: '',
  salidas2: '',
  producto2: '',
  cantidad2: '',
  unidad3: '',
  salidas3: '',
  producto3: '',
  cantidad3: '',
  importe: '',
}

const useStyles = makeStyles(() => ({
  iconButton: {
    width: '2rem',
    height: '2rem',
    marginRight: '-10px',
  },
  labelObservaciones: {
    backgroundColor: '#DEC65E',
  },
}))

const ServiceForm = ({ setOpenPopup, clients, places, cost, id }) => {
  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(initialValues)

  var {
    //lugar,
    //cliente,
    //referencia,
    unidad1,
    salidas1,
    producto1,
    cantidad1,
    unidad2,
    salidas2,
    producto2,
    cantidad2,
    unidad3,
    salidas3,
    producto3,
    cantidad3,
    importe,
  } = values

  const [client, setClient] = useState({})
  const [openClient, setOpenClient] = useState(false)

  const [place, setPlace] = useState({})
  const [openPlace, setOpenPlace] = useState(false)

  const [check1, setCheck1] = useState(true)
  const [check2, setCheck2] = useState(true)
  const [check, setCheck] = useState(false)

  const [flag, setFlag] = useState(false)

  const classes = useStyles()

  const [tMinimo, settMinimo] = useState(0)
  const [tMaximo, settMaximo] = useState(0)
  const [tInter, settInter] = useState(0)
  const [tRecomendado, settRecomendado] = useState(0)

  useEffect(() => {
    if (place.id === '') {
      if (
        totalMinimo !== 0 ||
        totalInter !== 0 ||
        totalMaximo !== 0 ||
        totalRecomendado !== 0
      ) {
        settMinimo(0)
        settInter(0)
        settMaximo(0)
        settRecomendado(0)
      }
    }
  })

  const validate = () => {
    let temp = {}

    if (flag === true) {
      temp.place =
        (place.nombre || '').length === 0 ? 'Este campo es requerido' : ''
      temp.unidad1 =
        (unidad1 || '').length === 0 ? 'Este campo es requerido' : ''
      temp.salidas1 = salidas1 < 1 ? 'Este campo es requerido' : ''
      temp.producto1 =
        (producto1 || '').length === 0 ? 'Este campo es requerido' : ''
      temp.cantidad1 = cantidad1 < 1 ? 'Este campo es requerido' : ''

      if (check1 === false) {
        temp.unidad2 =
          (unidad2 || '').length === 0 ? 'Este campo es requerido' : ''
        temp.salidas2 = salidas2 < 1 ? 'Este campo es requerido' : ''
        temp.producto2 =
          (producto2 || '').length === 0 ? 'Este campo es requerido' : ''
        temp.cantidad2 = cantidad2 < 1 ? 'Este campo es requerido' : ''

        if (check2 === false) {
          temp.unidad3 =
            (unidad3 || '').length === 0 ? 'Este campo es requerido' : ''
          temp.salidas3 = salidas3 < 1 ? 'Este campo es requerido' : ''
          temp.producto3 =
            (producto3 || '').length === 0 ? 'Este campo es requerido' : ''
          temp.cantidad3 = cantidad3 < 1 ? 'Este campo es requerido' : ''
        }
      }
    } else {
      temp.place =
        (place.nombre || '').length === 0 ? 'Este campo es requerido' : ''
      temp.client =
        (client.nombre || '').length === 0 ? 'Este campo es requerido' : ''
      temp.referencia =
        (client.referencia || '').length === 0 ? 'Este campo es requerido' : ''
      temp.unidad1 =
        (unidad1 || '').length === 0 ? 'Este campo es requerido' : ''
      temp.salidas1 = salidas1 < 1 ? 'Este campo es requerido' : ''
      temp.producto1 =
        (producto1 || '').length === 0 ? 'Este campo es requerido' : ''
      temp.cantidad1 = cantidad1 < 1 ? 'Este campo es requerido' : ''
      temp.importe =
        importe < tMinimo
          ? 'El importe final no puede ser menor a $ ' + tMinimo
          : importe < 1
          ? 'El importe no puede ser 0'
          : ''

      if (check1 === false) {
        temp.unidad2 =
          (unidad2 || '').length === 0 ? 'Este campo es requerido' : ''
        temp.salidas2 = salidas2 < 1 ? 'Este campo es requerido' : ''
        temp.producto2 =
          (producto2 || '').length === 0 ? 'Este campo es requerido' : ''
        temp.cantidad2 = cantidad2 < 1 ? 'Este campo es requerido' : ''

        if (check2 === false) {
          temp.unidad3 =
            (unidad3 || '').length === 0 ? 'Este campo es requerido' : ''
          temp.salidas3 = salidas3 < 1 ? 'Este campo es requerido' : ''
          temp.producto3 =
            (producto3 || '').length === 0 ? 'Este campo es requerido' : ''
          temp.cantidad3 = cantidad3 < 1 ? 'Este campo es requerido' : ''
        }
      }
    }
    setErrors({
      ...temp,
    })

    return Object.values(temp).every((x) => x === '')
  }

  const handleSubmit = (e) => {
    e.preventDefault()

    if (validate()) {
      if (flag === true) {
        if (
          (unidad1 === 'Nissan 2014' && place.distancia > 20) ||
          (unidad2 === 'Nissan 2014' && place.distancia > 20) ||
          (unidad3 === 'Nissan 2014' && place.distancia > 20)
        ) {
          Swal.fire({
            title: 'Advertencia',
            text: 'El rango maximo de kilometraje de la unidad Nissan es de 20 km',
            icon: 'warning',
            position: 'center',
            showConfirmButton: true,
            customClass: {
              container: 'my-swal',
            },
          })
        } else if (
          (unidad1 === 'Camioneta Ford' && place.distancia > 40) ||
          (unidad2 === 'Camioneta Ford' && place.distancia > 40) ||
          (unidad3 === 'Camioneta Ford' && place.distancia > 40)
        ) {
          Swal.fire({
            title: 'Advertencia',
            text: 'El rango maximo de kilometraje de la unidad Ford 2014 es de 40 km',
            icon: 'warning',
            position: 'center',
            showConfirmButton: true,
            customClass: {
              container: 'my-swal',
            },
          })
        } else {
          calculateService(
            place.nombre,
            client.nombre,
            client.referencia,
            unidad1,
            salidas1,
            producto1,
            cantidad1,
            unidad2,
            salidas2,
            producto2,
            cantidad2,
            unidad3,
            salidas3,
            producto3,
            cantidad3
          )
          settMinimo(totalMinimo)
          settInter(totalInter)
          settMaximo(totalMaximo)
          settRecomendado(totalRecomendado)
          setFlag(false)
        }
      } else {
        if (
          (unidad1 === 'Nissan 2014' && place.distancia > 20) ||
          (unidad2 === 'Nissan 2014' && place.distancia > 20) ||
          (unidad3 === 'Nissan 2014' && place.distancia > 20)
        ) {
          Swal.fire({
            title: 'Advertencia',
            text: 'El rango maximo de kilometraje de la unidad Nissan es de 20 km',
            icon: 'warning',
            position: 'center',
            showConfirmButton: true,
            customClass: {
              container: 'my-swal',
            },
          })
        } else if (
          (unidad1 === 'Camioneta Ford' && place.distancia > 40) ||
          (unidad2 === 'Camioneta Ford' && place.distancia > 40) ||
          (unidad3 === 'Camioneta Ford' && place.distancia > 40)
        ) {
          Swal.fire({
            title: 'Advertencia',
            text: 'El rango maximo de kilometraje de la unidad Ford 2014 es de 40 km',
            icon: 'warning',
            position: 'center',
            showConfirmButton: true,
            customClass: {
              container: 'my-swal',
            },
          })
        } else {
          addService(
            place.nombre,
            client.nombre,
            client.referencia,
            salidas1,
            salidas2,
            salidas3,
            cantidad1,
            cantidad2,
            cantidad3,
            unidad1,
            unidad2,
            unidad3,
            producto1,
            producto2,
            producto3,
            importe,
            place.distancia
          )
          resetForm()
          setValues({})
          setOpenPopup(false)
          Swal.fire('Hecho', 'Servicio registrado con éxito', 'success')
        }
      }
    }
  }

  const setStyle = (value) => {
    if (value === tRecomendado) {
      return '#00544E'
    }
  }

  const getAuthorization = () => {
    Swal.fire({
      title: 'Ingresa la contraseña',
      input: 'password',
      customClass: {
        container: 'my-swal',
      },
      showCancelButton: true,
      confirmButtonText: 'Validar',
      cancelButtonText: 'Cancelar',
      preConfirm: (value) => {
        // Validar si el valor ingresado es 'autorizaciones'
        if (value.toLowerCase() !== 'autorizaciones') {
          Swal.showValidationMessage(
            'La contraseña no es correcta. Inténtalo de nuevo.'
          )
          return false // Evita cerrar el SweetAlert si no es correcto
        }
        return true // Permite cerrar el SweetAlert si es correcto
      },
    }).then((result) => {
      if (result.isConfirmed) {
        settMinimo(0)

        Swal.fire('¡Correcto!', 'Autorización correcta.', 'success')
      }
    })
  }

  return (
    <div
      style={{
        justifyContent: 'center',
        display: 'flex',
        marginBottom: '10px',
      }}
    >
      <Form onSubmit={handleSubmit}>
        <div className="large-group">
          <div className="medium-group">
            <h3>Datos del Servicio a Domicilio</h3>
          </div>
          <div className="medium-group" />
          <div className="micro-group">
            <label>Folio</label>
            <label>
              <br /> {id}
            </label>
          </div>
          <div className="small-group">
            <label>* Cliente</label>
            <div style={{ display: 'flex', alignContent: 'center' }}>
              <div style={{ width: '100%', marginBottom: '20px' }}>
                <Controls.Input
                  id="cliente"
                  type="text"
                  name="cliente"
                  disabled
                  value={client.nombre}
                  onChange={handleInputChange}
                  error={errors.client}
                />
              </div>
              <div
                style={{
                  width: '20%',
                  marginLeft: '5px',
                }}
              >
                <Tooltip title="Seleccionar Cliente">
                  <IconButton
                    onClick={() => {
                      setOpenClient(true)
                    }}
                    sx={{ width: '2rem', height: '2rem' }}
                  >
                    <OpenInBrowser />
                  </IconButton>
                </Tooltip>
              </div>
              <div
                style={{
                  width: '20%',
                }}
              >
                <Tooltip title="Reiniciar Busqueda">
                  <IconButton
                    className={classes.iconButton}
                    onClick={() => {
                      setClient({
                        nombre: '',
                        calle: '',
                        numero: '',
                        colonia: '',
                        poblacion: '',
                      })
                    }}
                  >
                    <Close />
                  </IconButton>
                </Tooltip>
              </div>
            </div>
          </div>
          <div className="medium-group">
            <label>Domicilio</label>
            <br></br>
            <label key={client.calle}>
              {client.calle !== '' &&
                client.calle !== undefined &&
                client.calle +
                  ', #' +
                  client.numero +
                  ', ' +
                  client.colonia +
                  ', ' +
                  client.poblacion}
            </label>
          </div>
          <div className="special-group">
            <label>Referencia</label>
            <br></br>
            <label key={client.referencia}>{client.referencia}</label>
          </div>
          <div className="small-group">
            <br />
            <label>* Lugar</label>
            <div style={{ display: 'flex', alignContent: 'center' }}>
              <div style={{ width: '100%' }}>
                <Controls.Input
                  id="lugar"
                  type="text"
                  name="lugar"
                  disabled
                  value={place.nombre}
                  onChange={handleInputChange}
                  error={errors.place}
                />
              </div>
              <div
                style={{
                  width: '20%',
                  marginLeft: '5px',
                }}
              >
                <Tooltip title="Seleccionar Lugar">
                  <IconButton
                    onClick={() => {
                      setOpenPlace(true)
                      settMinimo(0)
                      settInter(0)
                      settMaximo(0)
                      settRecomendado(0)
                    }}
                    sx={{ width: '2rem', height: '2rem' }}
                  >
                    <OpenInBrowser />
                  </IconButton>
                </Tooltip>
              </div>
              <div
                style={{
                  width: '20%',
                }}
              >
                <Tooltip title="Reiniciar Busqueda">
                  <IconButton
                    className={classes.iconButton}
                    onClick={() => {
                      setPlace({
                        nombre: '',
                        zona: '',
                        observaciones: '',
                      })
                      settMinimo(0)
                      settInter(0)
                      settMaximo(0)
                      settRecomendado(0)
                    }}
                  >
                    <Close />
                  </IconButton>
                </Tooltip>
              </div>
            </div>
          </div>
          <div className="mini-group">
            <br />
            <label>Zona</label>
            <br />
            <label key={place.zona}>{place.zona}</label>
          </div>
          <div className="small-group">
            <br />
            <label>Observaciones</label>
            <br />
            <label
              className={classes.labelObservaciones}
              key={place.observaciones}
            >
              {place.observaciones}
            </label>
          </div>
          <div className="medium-group">
            <h3>Unidades por Salir</h3>
            <h4>Unidad 1</h4>
          </div>
          {/** Unidad 1 */}
          <div className="medium-group" />
          <div className="mini-group">
            <label>Unidad</label>
            <Controls.Select
              id="unidad1"
              type="text"
              name="unidad1"
              value={unidad1}
              onChange={handleInputChange}
              options={unidades}
            />
            {errors.unidad1 !== '' && (
              <label
                style={{
                  color: '#d32f2f',
                  fontFamily: '"Roboto","Helvetica","Arial",sans-serif',
                  fontWeight: '400',
                  fontSize: '0.75rem',
                  lineHeight: '1.66',
                  letterSpacing: '0.03333em',
                  textAlign: 'left',
                  marginTop: '3px',
                  marginRight: '0',
                  marginBottom: '0',
                  marginLeft: '20px',
                }}
              >
                {errors.unidad1}
              </label>
            )}
          </div>
          <div className="mini-group">
            <label>Salidas</label>
            <Controls.Input
              id="salidas1"
              name="salidas1"
              type="number"
              placeholder="0"
              min="0"
              pattern="^[0-9]+"
              value={salidas1}
              onChange={handleInputChange}
              error={errors.salidas1}
            />
          </div>
          <div className="mini-group">
            <label>Producto</label>
            <Controls.Select
              id="producto1"
              type="text"
              name="producto1"
              value={producto1}
              onChange={handleInputChange}
              options={productos}
            />
            {errors.producto1 !== '' && (
              <label
                style={{
                  color: '#d32f2f',
                  fontFamily: '"Roboto","Helvetica","Arial",sans-serif',
                  fontWeight: '400',
                  fontSize: '0.75rem',
                  lineHeight: '1.66',
                  letterSpacing: '0.03333em',
                  textAlign: 'left',
                  marginTop: '3px',
                  marginRight: '0',
                  marginBottom: '0',
                  marginLeft: '20px',
                }}
              >
                {errors.producto1}
              </label>
            )}
          </div>
          <div className="mini-group">
            <label>Cantidad</label>
            <Controls.Input
              id="cantidad1"
              name="cantidad1"
              type="number"
              placeholder="0"
              min="0"
              pattern="^[0-9]+"
              value={cantidad1}
              onChange={handleInputChange}
              error={errors.cantidad1}
            />
          </div>
          {/** Unidad 2 */}
          <div className="medium-group">
            <h4>
              Unidad 2
              <input
                type="checkbox"
                style={{ marginLeft: '10px' }}
                value={check1}
                onChange={() => {
                  if (check1 === true) {
                    setCheck1(false)
                  } else {
                    setCheck(false)
                    setCheck1(true)
                    setCheck2(true)
                  }
                }}
              />
            </h4>
          </div>
          <div className="medium-group" />
          <div className="mini-group">
            <label>Unidad</label>
            <Controls.Select
              id="unidad2"
              type="text"
              name="unidad2"
              value={unidad2}
              onChange={handleInputChange}
              options={unidades}
              disabled={check1}
            />
            {errors.unidad2 !== '' && (
              <label
                style={{
                  color: '#d32f2f',
                  fontFamily: '"Roboto","Helvetica","Arial",sans-serif',
                  fontWeight: '400',
                  fontSize: '0.75rem',
                  lineHeight: '1.66',
                  letterSpacing: '0.03333em',
                  textAlign: 'left',
                  marginTop: '3px',
                  marginRight: '0',
                  marginBottom: '0',
                  marginLeft: '20px',
                }}
              >
                {errors.unidad2}
              </label>
            )}
          </div>
          <div className="mini-group">
            <label>Salidas</label>
            <Controls.Input
              id="salidas2"
              name="salidas2"
              type="number"
              placeholder="0"
              min="0"
              pattern="^[0-9]+"
              value={salidas2}
              onChange={handleInputChange}
              disabled={check1}
              error={errors.salidas2}
            />
          </div>
          <div className="mini-group">
            <label>Producto</label>
            <Controls.Select
              id="producto2"
              type="text"
              name="producto2"
              value={producto2}
              onChange={handleInputChange}
              options={productos}
              disabled={check1}
            />
            {errors.producto2 !== '' && (
              <label
                style={{
                  color: '#d32f2f',
                  fontFamily: '"Roboto","Helvetica","Arial",sans-serif',
                  fontWeight: '400',
                  fontSize: '0.75rem',
                  lineHeight: '1.66',
                  letterSpacing: '0.03333em',
                  textAlign: 'left',
                  marginTop: '3px',
                  marginRight: '0',
                  marginBottom: '0',
                  marginLeft: '20px',
                }}
              >
                {errors.producto2}
              </label>
            )}
          </div>
          <div className="mini-group">
            <label>Cantidad</label>
            <Controls.Input
              id="cantidad2"
              name="cantidad2"
              type="number"
              placeholder="0"
              min="0"
              pattern="^[0-9]+"
              value={cantidad2}
              onChange={handleInputChange}
              disabled={check1}
              error={errors.cantidad2}
            />
          </div>
          {/** Unidad 3 */}
          <div className="medium-group">
            <h4>
              Unidad 3
              <input
                type="checkbox"
                style={{ marginLeft: '10px' }}
                value={check2}
                disabled={check1}
                checked={check}
                onClick={() => {
                  if (check === true) {
                    setCheck(false)
                  } else {
                    setCheck(true)
                  }
                }}
                onChange={() => {
                  if (check2 === true) {
                    setCheck2(false)
                  } else {
                    setCheck2(true)
                  }
                }}
              />
            </h4>
          </div>
          <div className="medium-group" />
          <div className="mini-group">
            <label>Unidad</label>
            <Controls.Select
              id="unidad3"
              type="text"
              name="unidad3"
              value={unidad3}
              onChange={handleInputChange}
              options={unidades}
              disabled={check2}
            />
            {errors.unidad3 !== '' && (
              <label
                style={{
                  color: '#d32f2f',
                  fontFamily: '"Roboto","Helvetica","Arial",sans-serif',
                  fontWeight: '400',
                  fontSize: '0.75rem',
                  lineHeight: '1.66',
                  letterSpacing: '0.03333em',
                  textAlign: 'left',
                  marginTop: '3px',
                  marginRight: '0',
                  marginBottom: '0',
                  marginLeft: '20px',
                }}
              >
                {errors.unidad3}
              </label>
            )}
          </div>
          <div className="mini-group">
            <label>Salidas</label>
            <Controls.Input
              id="salidas3"
              name="salidas3"
              type="number"
              placeholder="0"
              min="0"
              pattern="^[0-9]+"
              value={salidas3}
              onChange={handleInputChange}
              disabled={check2}
              error={errors.salidas3}
            />
          </div>
          <div className="mini-group">
            <label>Producto</label>
            <Controls.Select
              className="input-select"
              id="producto3"
              name="producto3"
              value={producto3}
              onChange={handleInputChange}
              options={productos}
              disabled={check2}
            />
            {errors.producto3 !== '' && (
              <label
                style={{
                  color: '#d32f2f',
                  fontFamily: '"Roboto","Helvetica","Arial",sans-serif',
                  fontWeight: '400',
                  fontSize: '0.75rem',
                  lineHeight: '1.66',
                  letterSpacing: '0.03333em',
                  textAlign: 'left',
                  marginTop: '3px',
                  marginRight: '0',
                  marginBottom: '0',
                  marginLeft: '20px',
                }}
              >
                {errors.producto3}
              </label>
            )}
          </div>
          <div className="mini-group">
            <label>Cantidad</label>
            <Controls.Input
              id="cantidad3"
              name="cantidad3"
              type="number"
              placeholder="0"
              min="0"
              pattern="^[0-9]+"
              value={cantidad3}
              onChange={handleInputChange}
              disabled={check2}
              error={errors.cantidad3}
            />
          </div>
          <div
            className="micro-group"
            style={{
              marginTop: '20px',
            }}
          >
            <label>
              Importe Minimo: <br />
            </label>
            <Controls.Input
              editable={false}
              value={tMinimo}
              style={{
                borderColor: setStyle(tMinimo),
                color: setStyle(tMinimo),
                borderWidth: '3px',
              }}
            />
          </div>
          <div className="micro-group" style={{ marginTop: '20px' }}>
            <label>
              Importe Intermedio: <br />
            </label>
            <Controls.Input
              editable={false}
              value={tInter}
              style={{
                borderColor: setStyle(tInter),
                color: setStyle(tInter),
                borderWidth: '3px',
              }}
            />
          </div>
          <div className="micro-group" style={{ marginTop: '20px' }}>
            <label>
              Importe Maximo: <br />
            </label>
            <Controls.Input
              editable={false}
              value={tMaximo}
              style={{
                borderColor: setStyle(tMaximo),
                color: setStyle(tMaximo),
                borderWidth: '3px',
              }}
            />
          </div>
          <div className="micro-group" style={{ marginTop: '20px' }}>
            <label>Importe: </label>
            <div style={{ display: 'flex', alignContent: 'center' }}>
              <div style={{ width: '100%', marginBottom: '20px' }}>
                <input
                  className="input-select"
                  id="importe"
                  name="importe"
                  type="number"
                  placeholder={'$ ' + tRecomendado}
                  min={tMinimo}
                  max={tMaximo}
                  //pattern="^[0-9]+"
                  onChange={handleInputChange}
                  value={importe}
                />
              </div>
              <div style={{ width: '20%', marginLeft: '5px' }}>
                <Tooltip title="Modificar precio">
                  <IconButton onClick={getAuthorization}>
                    <LockOpen></LockOpen>
                  </IconButton>
                </Tooltip>
              </div>
            </div>
            {errors.importe !== '' && (
              <label
                style={{
                  color: '#d32f2f',
                  fontFamily: '"Roboto","Helvetica","Arial",sans-serif',
                  fontWeight: '400',
                  fontSize: '0.75rem',
                  lineHeight: '1.66',
                  letterSpacing: '0.03333em',
                  textAlign: 'left',
                  marginTop: '3px',
                  marginRight: '0',
                  marginBottom: '0',
                  marginLeft: '20px',
                }}
              >
                {errors.importe}
              </label>
            )}
          </div>
          <div className="small-group" style={{ marginTop: '40px' }}>
            <button
              className="btn_extra"
              onClick={() => {
                setFlag(true)
              }}
            >
              Calcular Servicio
            </button>
          </div>
          <div className="small-group" style={{ marginTop: '40px' }}>
            <button className="btn" onClick={handleSubmit}>
              Guardar Servicio
            </button>
          </div>
        </div>
        <CustomDialog
          title="Clientes"
          openPopup={openClient}
          setOpenPopup={setOpenClient}
          maxWidth="sm"
        >
          <ClientsSelect setOpenPopup={setOpenClient} setClient={setClient} />
        </CustomDialog>
        <CustomDialog
          title="Lugares"
          openPopup={openPlace}
          setOpenPopup={setOpenPlace}
          maxWidth="sm"
        >
          <PlaceSelect setOpenPopup={setOpenPlace} setPlace={setPlace} />
        </CustomDialog>
      </Form>
    </div>
  )
}

export default ServiceForm
