import { useState } from 'react'
import { makeStyles } from '@material-ui/core'

export function useForm(initialValues) {
  const [values, setValues] = useState(initialValues)
  const [errors, setErrors] = useState({})

  const handleInputChange = (e) => {
    const { name, value } = e.target
    setValues({
      ...values,
      [name]: value,
    })
  }

  const resetForm = () => {
    setValues(initialValues)
    setErrors({})
  }

  return {
    values,
    setValues,
    errors,
    setErrors,
    handleInputChange,
    resetForm,
  }
}

const useStyles = makeStyles({
  root: {
    '& .MuiTextField-root': {
      width: '100%',
    },
  },
})

export function Form(props) {
  const classes = useStyles()
  const { children, ...other } = props

  return (
    <form className={classes.root} {...other}>
      {props.children}
    </form>
  )
}
