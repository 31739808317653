import React, { useLayoutEffect } from 'react'

import { useForm, Form } from '../UI/Form'
import Controls from '../UI/Controls'

import Swal from 'sweetalert2'
import { addDriver, updateDriver } from '../../services/drivers'

const initialValues = {
  nombre: '',
  puesto: '',
}

export const WorkerForm = ({ setOpenPopup, update, flag, setFlag }) => {
  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(initialValues)

  let { nombre, puesto } = values

  const validate = () => {
    let temp = {}

    temp.nombre = (nombre || '').length === 0 ? 'Este campo es requerido' : ''
    temp.puesto = (puesto || '').length === 0 ? 'Este campo es requerido' : ''

    setErrors({
      ...temp,
    })

    return Object.values(temp).every((x) => x === '')
  }

  useLayoutEffect(() => {
    if (update !== '') {
      setValues({ ...update })
    }
  }, [setValues, update, flag])

  const handleSubmit = (e) => {
    e.preventDefault()
    if (validate()) {
      if (flag === false) {
        addDriver(values)
      } else {
        updateDriver(update.id, nombre, puesto)
      }
      resetForm()
      setValues({})
      setFlag(false)
      setOpenPopup(false)
      Swal.fire('Hecho', 'Trabajador registrado con éxito', 'success')
    }
  }

  return (
    <div
      style={{
        justifyContent: 'center',
        display: 'flex',
        marginBottom: '10px',
      }}
    >
      <Form onSubmit={handleSubmit}>
        <div className="large-group">
          <div className="medium-group" style={{ marginBottom: '20px' }}>
            <label htmlFor="nombre">* Nombre</label>
            <Controls.Input
              className="input-select"
              id="nombre"
              type="text"
              name="nombre"
              value={nombre}
              onChange={handleInputChange}
              error={errors.nombre}
            />
          </div>
          <div className="medium-group" style={{ marginBottom: '20px' }}>
            <label htmlFor="puesto">* Puesto</label>
            <Controls.Select
              id="puesto"
              type="text"
              name="puesto"
              value={puesto}
              onChange={handleInputChange}
              options={[
                { id: '', title: 'Selecciona una Opción' },
                { id: 'Chofer', title: 'Chofer' },
                { id: 'Ayudante', title: 'Ayudante' },
              ]}
            />
            {errors.puesto !== '' && (
              <label
                style={{
                  color: '#d32f2f',
                  fontFamily: '"Roboto","Helvetica","Arial",sans-serif',
                  fontWeight: '400',
                  fontSize: '0.75rem',
                  lineHeight: '1.66',
                  letterSpacing: '0.03333em',
                  textAlign: 'left',
                  marginTop: '3px',
                  marginRight: '0',
                  marginBottom: '0',
                  marginLeft: '20px',
                }}
              >
                {errors.puesto}
              </label>
            )}
          </div>
          <input id="submit" className="btn" type="submit" name="submit" />
        </div>
      </Form>
    </div>
  )
}
