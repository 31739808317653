import { Routes, Route } from 'react-router-dom'

import { NavbarAdmin } from '../components/UI/Navbar/Index-Admin'
import { NavbarUser } from '../components/UI/Navbar/Index-User'
import { FooterAdmin } from '../components/UI/Footer/Index-Admin'
import { FooterUser } from '../components/UI/Footer/Index-User'
import { Information } from '../components/Information'
import { Places } from '../components/Places'
import { Calculator } from '../components/Calculator'
import { Cost } from '../components/Cost'
import { Deliveries } from '../components/Deliveries'
import { Clients } from '../components/Clients'
import { Messages } from '../components/Messages'

import { auth } from '../configs/firebase-config'
import { Blog } from '../components/Blog'

export const DashboardRoutes = () => {
  return (
    <>
      {auth.currentUser.email === 'diegofs117@outlook.com' ||
      auth.currentUser.email === 'mat.preconsa_admon@outlook.com' ||
      auth.currentUser.email === 'mat_preconsa@outlook.com' ||
      auth.currentUser.email === 'mat.preconsa_mauricio@hotmail.com' ? (
        <>
          <NavbarAdmin />
          <Routes>
            <Route path="*" element={<Information />} />
            <Route path="info" element={<Information />} />
            <Route path="places" element={<Places />} />
            <Route path="calculator" element={<Calculator />} />
            <Route path="cost" element={<Cost />} />
            <Route path="deliveries" element={<Deliveries />} />
            <Route path="clients" element={<Clients />} />
            <Route path="messages" element={<Messages />} />
            <Route path="blog" element={<Blog />} />
          </Routes>
          <FooterAdmin />
        </>
      ) : (
        <>
          <NavbarUser />
          <Routes>
            <Route path="*" element={<Information />} />
            <Route path="info" element={<Information />} />
            <Route path="places" element={<Places />} />
            <Route path="calculator" element={<Calculator />} />
            <Route path="deliveries" element={<Deliveries />} />
            <Route path="clients" element={<Clients />} />
            <Route path="messages" element={<Messages />} />
          </Routes>
          <FooterUser />
        </>
      )}
    </>
  )
}
