import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from '@mui/material'

import { Close } from '@mui/icons-material'

export const CustomDialog = (props) => {
  const { title, children, openPopup, setOpenPopup, maxWidth } = props

  return (
    <Dialog
      open={openPopup}
      maxWidth={maxWidth}
      fullWidth={true}
      sx={{ zIndex: '100' }}
      disableEnforceFocus
      disableAutoFocus
    >
      <DialogTitle>
        <div style={{ display: 'flex' }}>
          <Typography variant="h6" component="div" style={{ flexGrow: 1 }}>
            {title}
          </Typography>
          <IconButton
            onClick={() => {
              setOpenPopup(false)
            }}
          >
            <Close />
          </IconButton>
        </div>
      </DialogTitle>
      <DialogContent dividers>{children}</DialogContent>
    </Dialog>
  )
}
