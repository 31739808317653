import React, { useState, useLayoutEffect } from 'react'

import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'

import { addBlog, updateBlog } from '../../services/blog'
import { useForm, Form } from '../UI/Form'

import Swal from 'sweetalert2'
import Controls from '../UI/Controls'
import { fecha } from '../../services/datetime'

const initialValues = {
  titulo: '',
  tipo: '',
  fechaPublicacion: '',
  contenido: '',
  id: '',
}

const BlogForm = ({ setOpenPopup, update, flag, setFlag }) => {
  const [value, setValue] = useState('')

  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(initialValues)

  let { titulo, tipo, fechaPublicacion, contenido } = values

  const validate = () => {
    let temp = {}

    temp.titulo =
      (titulo || '').length === 0
        ? 'Este campo es requerido'
        : titulo.length < 4
        ? 'El título debe tener al menos 4 caracteres'
        : ''
    temp.tipo =
      (tipo || '').length === 0
        ? 'Este campo es requerido'
        : tipo.length < 3
        ? 'El tipo debe tener al menos 4 caracteres'
        : ''
    temp.value = (value || '').length === 0 ? 'Este campo es requerido' : ''

    setErrors({
      ...temp,
    })

    return Object.values(temp).every((x) => x === '')
  }

  useLayoutEffect(() => {
    if (flag === true) {
      setValues({ ...update })
    }
  }, [setValues, update, flag])

  const modules = {
    toolbar: [
      [{ font: [] }],
      [{ size: [] }],
      ['bold', 'italic'],
      [
        { align: [] },
        { list: 'ordered' },
        { list: 'bullet' },
        { indent: '-1' },
        { indent: '+1' },
      ],
      ['link', 'image', 'video'],
      ['clean'],
    ],
    clipboard: {
      matchVisual: false,
    },
  }

  const formats = [
    'font',
    'size',
    'bold',
    'italic',
    'underline',
    'strike',
    'blockquote',
    'align',
    'list',
    'bullet',
    'indent',
    'link',
    'image',
    'video',
  ]

  const handleSubmit = (e) => {
    e.preventDefault()
    if (validate()) {
      contenido = value
      if (flag === false) {
        fechaPublicacion = fecha()
        addBlog(titulo, tipo, fechaPublicacion, contenido)
      } else {
        updateBlog(update.id, titulo, tipo, fechaPublicacion, contenido)
      }
      resetForm()
      setValues({})
      setFlag(false)
      setOpenPopup(false)
      Swal.fire('Hecho', 'Noticia registrada con éxito', 'success')
    }
  }

  return (
    <div
      style={{
        justifyContent: 'center',
        display: 'flex',
        marginBottom: '10px',
      }}
    >
      <Form onSubmit={handleSubmit}>
        <div className="large-group">
          <div className="small-group" style={{ marginBottom: '20px' }}>
            <label htmlFor="fecha">Fecha</label>
            <br />
            <label className="h3" id="fecha" type="text" name="fecha">
              {fechaPublicacion || fecha()}
            </label>
          </div>
          <div className="small-group" style={{ marginBottom: '20px' }}>
            <label htmlFor="titulo">* Titulo</label>
            <Controls.Input
              id="titulo"
              type="text"
              name="titulo"
              value={titulo}
              onChange={handleInputChange}
              error={errors.titulo}
            />
          </div>
          <div className="small-group" style={{ marginBottom: '20px' }}>
            <label htmlFor="tipo">* Tipo</label>
            <Controls.Input
              id="tipo"
              type="text"
              name="tipo"
              value={tipo}
              onChange={handleInputChange}
              error={errors.tipo}
            />
          </div>
          <div className="special-group" style={{ marginBottom: '20px' }}>
            <ReactQuill
              style={{ backgroundColor: '#e9e9e9' }}
              theme="snow"
              modules={modules}
              formats={formats}
              defaultValue={update.contenido}
              onChange={(content) => setValue(content)}
            />
            {errors.value !== '' && (
                <label
                  style={{
                    color: '#d32f2f',
                    fontFamily: '"Roboto","Helvetica","Arial",sans-serif',
                    fontWeight: '400',
                    fontSize: '0.75rem',
                    lineHeight: '1.66',
                    letterSpacing: '0.03333em',
                    textAlign: 'left',
                    marginTop: '3px',
                    marginRight: '0',
                    marginBottom: '0',
                    marginLeft: '20px',
                  }}
                >
                  {errors.value}
                </label>
              )}
          </div>
          <input id="submit" className="btn" type="submit" name="submit" />
        </div>
      </Form>
    </div>
  )
}

export default BlogForm
