import React, { Component } from 'react'

export class Loading extends Component {
  render() {
    return (
        <div style={{height: '100vh', backgroundColor: '#00544e'}}>
            <div className='loader-container'> Cargando... </div>
        </div>
    )
  }
}
