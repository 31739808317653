import {useState, useLayoutEffect} from 'react'
import {onValue, ref } from 'firebase/database'
import {database} from '../configs/firebase'

export const useMessages = () => {
    const  [messages, setMessages] = useState([])

    useLayoutEffect(() => {
        onValue(
            ref(database, 'mensajes/'),
            (snapshot) => {
                const messagesList = []
                snapshot.forEach((item) => {
                    const messageItem = {
                        id: item.key,
                        correo: item.val().correo,
                        fecha: item.val().fecha,
                        mensaje: item.val().mensaje,
                        nombre: item.val().nombre,
                        telefono: item.val().telefono
                    }
                    messagesList.push(messageItem)
                })
                setMessages(messagesList)
            },
            (error) => {
                console.log(error)
            }
        )
    }, [])

    return [messages]
}