import React, { useLayoutEffect } from 'react'

import { updateCost } from '../../services/cost'
import { useForm, Form } from '../UI/Form'

import Swal from 'sweetalert2'
import Controls from '../UI/Controls'

const initialValues = {
  concepto: '',
  costoAct: '',
  tipo: '',
  valor: '',
}

const CostosForm = ({ setOpenPopup, update }) => {
  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(initialValues)

  var { concepto, costoAct, tipo, valor } = values

  const validate = () => {
    let temp = {}

    temp.concepto =
      (concepto || '').length === 0 ? 'Este campo es requerido' : ''
    temp.costoAct =
      (costoAct || '').length === 0 ? 'Este campo es requerido' : ''
    temp.tipo = (tipo || '').length === 0 ? 'Este campo es requerido' : ''
    temp.valor = (valor || '').length === 0 ? 'Este campo es requerido' : ''

    setErrors({
      ...temp,
    })

    return Object.values(temp).every((x) => x === '')
  }

  useLayoutEffect(() => {
    if (update !== {}) {
      setValues({ ...update })
    }
  }, [setValues, update])

  const handleSubmit = (e) => {
    e.preventDefault()
    if (validate()) {
      updateCost(update.id, concepto, costoAct, tipo, valor)
      resetForm()
      setValues({})
      setOpenPopup(false)
      Swal.fire('Hecho', 'Costo modificado con éxito', 'success')
    }
  }

  return (
    <div
      style={{
        justifyContent: 'center',
        display: 'flex',
        marginBottom: '10px',
      }}
    >
      <Form onSubmit={handleSubmit}>
        <div className="large-group">
          <div className="mini-group" style={{ marginBottom: '40px' }}>
            <label htmlFor="concepto">* Concepto</label>
            <Controls.Input
              id="concepto"
              type="text"
              name="concepto"
              disabled
              value={concepto}
              onChange={handleInputChange}
              error={errors.concepto}
            />
          </div>
          <div className="mini-group" style={{ marginBottom: '40px' }}>
            <label htmlFor="tipo">* Tipo de Costo</label>
            <Controls.Select 
              className="input-select"
              id="tipo"
              type="text"
              name="tipo"
              value={tipo}
              onChange={handleInputChange}
              options={[
                {id: 'Recursos Humanos', title: 'Recursos Humanos'},
                {id: 'Rendimiento Combustible', title: 'Rendimiento Combustible'},
                {id: 'Rendimiento Neumáticos', title: 'Rendimiento Neumáticos'},
                {id: 'Combustibles y Lubricantes', title: 'Combustibles y Lubricantes'},
                {id: 'Servicio/Mantenimiento', title: 'Servicio/Mantenimiento'},
                {id: 'Cargo Extra', title: 'Cargo Extra'},
                {id: 'Impuestos', title: 'Impuestos'},
                {id: 'Utilidad', title: 'Utilidad'}
              ]}
            />
            {errors.tipo !== '' && (
              <label
                style={{
                  color: '#d32f2f',
                  fontFamily: '"Roboto","Helvetica","Arial",sans-serif',
                  fontWeight: '400',
                  fontSize: '0.75rem',
                  lineHeight: '1.66',
                  letterSpacing: '0.03333em',
                  textAlign: 'left',
                  marginTop: '3px',
                  marginRight: '0',
                  marginBottom: '0',
                  marginLeft: '20px',
                }}
              >
                {errors.tipo}
              </label>
            )}
          </div>
          <div className="mini-group" style={{ marginBottom: '40px' }}>
            <label htmlFor="costoAct">* Importe Actual</label>
            <Controls.Input
              id="costoAct"
              type="text"
              name="costoAct"
              value={costoAct}
              onChange={handleInputChange}
              error={errors.costoAct}
            />
          </div>
          <div className="mini-group" style={{ marginBottom: '40px' }}>
            <label htmlFor="costoAct">* Valor</label>
            <Controls.Input
              id="valor"
              type="text"
              name="valor"
              value={valor}
              onChange={handleInputChange}
              error={errors.valor}
            />
          </div>
          <input id="submit" className="btn" type="submit" name="submit" />
        </div>
      </Form>
    </div>
  )
}

export default CostosForm
