import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signOut,
  updateProfile,
} from 'firebase/auth'
import { getDatabase, ref, set } from 'firebase/database'
import Swal from 'sweetalert2'
import { auth } from '../../configs/firebase-config'
import { types } from '../../types'

export const loginWithEmailPassword = (email, password) => {
  return (dispatch) => {
    signInWithEmailAndPassword(auth, email, password)
      .then(({ user }) => {
        dispatch(login(user.uid, user.displayName))
        
      })
      .catch((e) => {
        Swal.fire('Oops', 'Algo salio mal', 'error')
      })
  }
}

export const registerWithEmailPassword = (email, password, name) => {
  return (dispatch) => {
    createUserWithEmailAndPassword(auth, email, password)
      .then(async ({ user }) => {
        await updateProfile(user, { displayName: name })

        await dispatch(login(user.uid, user.displayName))

        const db = getDatabase()
        await set(ref(db, `perfiles/${user.uid}`), {
          nombre: name,
          email,
          estatus: true,
          rol: 'empleado',
        })
      }).catch((e) => {
        Swal.fire('Oops', 'Algo salio mal', 'error')
      })
  }
}

export const login = (uid, displayName) => ({
  type: types.login,
  payload: {
    uid,
    displayName,
  },
})

export const logout = () => {
  return async (dispatch) => {
    await signOut(auth)
    dispatch(logoutUser())
  }
}

export const logoutUser = () => ({
  type: types.logout,
})
