import React from 'react'

export const CostDetails = ({ details }) => {
  return (
    <div className="large-group" style={{ margin: '20px' }}>
      <div className="mini-group">
        <h3>Concepto: </h3>
        <label>{details.concepto}</label>
      </div>
      <div className="mini-group">
        <h3>Tipo: </h3>
        <label>{details.tipo}</label>
      </div>
      <div className="mini-group">
        <h3>Valor: </h3>
        <label>{details.valor}</label>
      </div>
      <div className="mini-group">
        <h3>Importe Actual: </h3>
        <label>${details.costoAct}</label>
      </div>
    </div>
  )
}
