import { Provider } from 'react-redux'

import { store } from './redux/store'
import { AppRouter } from './routers/AppRouter'

export const DashboardApp = () => {
    return (
        <Provider store={store}>
            <AppRouter />
        </Provider>
    )
}