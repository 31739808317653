import { initializeApp } from 'firebase/app'
import { getAuth } from 'firebase/auth'
import { getDatabase } from 'firebase/database'

const firebaseConfig = {
  apiKey: 'AIzaSyCTVGPY77I1bOwGFfUxGYzmTqjqdRGUx1w',
  authDomain: 'sa-preconsa.firebaseapp.com',
  databaseURL: 'https://sa-preconsa-default-rtdb.firebaseio.com',
  projectId: 'sa-preconsa',
  storageBucket: 'sa-preconsa.appspot.com',
  messagingSenderId: '232908838595',
  appId: '1:232908838595:web:a6396235514df10fed3476',
  measurementId: 'G-GLJ8YSJJGS',
}

const app = initializeApp(firebaseConfig)
export const auth = getAuth(app)
export const database = getDatabase(app)
export default app
