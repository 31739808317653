import { useState, useLayoutEffect } from 'react'
import { onValue, ref, getDatabase, push, set } from 'firebase/database'
import { database } from '../configs/firebase'

export var addId = ''

export const useClients = () => {
  const [clients, setclients] = useState([])

  useLayoutEffect(() => {
    onValue(
      ref(database, 'clientes/'),
      (snapshot) => {
        const clientsList = []
        snapshot.forEach((item) => {
          const clientItem = {
            id: item.key,
            calle: item.val().calle,
            colonia: item.val().colonia,
            nc: item.val().nc,
            nombre: item.val().nombre,
            numero: item.val().numero,
            observaciones: item.val().observaciones,
            poblacion: item.val().poblacion,
            referencia: item.val().referencia,
            municipio: item.val().municipio,
            telefono: item.val().telefono
          }
          clientsList.push(clientItem)
          addId = snapshot.size + 1
        })
        setclients(clientsList)
      },
      (error) => {
        console.log(error)
      }
    )
  }, [])

  return [clients]
}

export const addClient = (
  calle,
  colonia,
  id,
  nombre,
  numero,
  observaciones,
  poblacion,
  referencia,
  municipio,
  telefono,
) => {
  const db = getDatabase()

  if (observaciones === undefined) {
    observaciones = '...'
  }

  

  push(ref(db, 'clientes'), {
    calle: calle,
    colonia: colonia,
    nc: addId,
    nombre: nombre,
    numero: numero,
    observaciones: observaciones,
    poblacion: poblacion,
    referencia: referencia,
    municipio: municipio,
    telefono:telefono,
  })
}

export const updateClient = (
  id,
  calle,
  colonia,
  nc,
  nombre,
  numero,
  observaciones,
  poblacion,
  referencia,
  municipio,
  telefono,
) => {
  const db = getDatabase()

  if (observaciones === undefined) {
    observaciones = '...'
  }

  set(ref(db, `clientes/${id}`), {
    calle: calle,
    colonia: colonia,
    nc: nc,
    nombre: nombre,
    numero: numero,
    observaciones: observaciones,
    poblacion: poblacion,
    referencia: referencia,
    municipio: municipio,
    telefono:telefono,
  })
}