import { Summarize } from '@mui/icons-material'
import { Button } from '@mui/material'
import React from 'react'
import { driverReport } from '../../services/reports'

export const DeliverieDetails = ({ details }) => {
  return (
    <div className="large-group">
      <div className="micro-group">
        <h3>Fecha: </h3>
        <label>{details.fecha}</label>
      </div>
      <div className="micro-group">
        <h3>Cliente: </h3>
        <label>{details.cliente}</label>
      </div>
      <div className="micro-group">
        <h3>Producto: </h3>
        <label>{details.producto}</label>
      </div>
      <div className="micro-group">
        <h3>Lugar: </h3>
        <label>{details.lugar}</label>
      </div>
      <div className="micro-group">
        <h3>Personal</h3>
        <label>
          {details.chofer}, {details.ayudante}, {details.ayudante2}
        </label>
      </div>
      <div className="mini-group">
        <h3>Inicio de Carga: </h3>
        <label>{details.hrInicio}</label>
      </div>
      <div className="mini-group">
        <h3>Salida de Planta: </h3>
        <label>{details.hrSalida}</label>
      </div>
      <div className="mini-group">
        <h3>Llegada a Planta: </h3>
        <label>{details.hrEntrada}</label>
      </div>
      <div className="mini-group">
        <h3>Tiempo Estimado de Regreso: </h3>
        <label>{details.tiempo}</label>
      </div>
      <div className="small-group">
        <h3>Tiempo Invertido de Carga: </h3>
        <label>{details.carga} Horas</label>
      </div>
      <div className="small-group">
        <h3>Tiempo Invertido de Entrega: </h3>
        <label>{details.entrega} Horas</label>
      </div>
      <div className="small-group">
        <h3>Tiempo Total Invertido: </h3>
        <label>{details.total} Horas</label>
      </div>
      <div className="small-group"></div>
      <div className="small-group">
        <Button
          variant="contained"
          startIcon={<Summarize />}
          sx={{
            backgroundColor: '#009696',
            padding: '12px 15px',
            height: '40px',
            fontSize: '1em;',
            border: '1px solid #00544e',
            borderRadius: '30px',
            transition: '0.3s all ease',
            color: 'secondary',
            marginTop: '20px',
            '&:hover': {
              backgroundColor: '#00544e',
              border: '1px solid #009696',
            },
            '@media (max-width: 700px)': {
              marginBottom: '20px',
            },
          }}
          onClick={() => {
            driverReport(details)
          }}
        >
          Imprimir Detalles
        </Button>
      </div>
      <div className="small-group"></div>
    </div>
  )
}
