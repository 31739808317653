import React from 'react'

export const ClientDetails = ({setOpenPopup, details}) => {

  return (
    <div className="large-group">
      <div className="mini-group">
        <h3>Id: </h3>
        <label>{details.nc}</label>
      </div>
      <div className="medium-group">
        <h3>Nombre: </h3>
        <label>{details.nombre}</label>
      </div>
      <div className="small-group">
        <h3>Telefono: </h3>
        <label>{details.telefono}</label>
      </div>
      <div className="mini-group">
        <h3>Domicilio: </h3>
        <label>
          {details.calle} #{details.numero}
        </label>
      </div>
      <div className="mini-group">
        <h3>Colonia: </h3>
        <label>{details.colonia}</label>
      </div>
      <div className="mini-group">
        <h3>Población: </h3>
        <label>{details.poblacion}</label>
      </div>
      <div className="mini-group">
        <h3>Municipio: </h3>
        <label>{details.municipio}</label>
      </div>
      <div className="medium-group">
        <h3>Observaciones: </h3>
        <label style={{ textAlign: 'justify', display: 'flex' }}>
          {details.observaciones}
        </label>
      </div>
      <div className="medium-group">
        <h3>Referencia: </h3>
        <label style={{ textAlign: 'justify', display: 'flex' }}>
          {details.referencia}
        </label>
      </div>
    </div>
  )
}
