import { FetchServices, getServices } from '../services/services'

export const ticketSelectCashRegister = () => {
  const [tickets] = FetchServices()
  const filterOnlyTicketsNonPaid = tickets.filter(
    (ticket) => ticket.estado === 'A crédito' && ticket.estado !== 'Cancelada'
  )

  let selectOptionTickets = []
  filterOnlyTicketsNonPaid.forEach(function ({ id, folio, cliente }) {
    const dataSelect = {
      id,
      title: `${folio} - ${cliente}`,
    }
    selectOptionTickets.push(dataSelect)
  })
  return selectOptionTickets
}

export const showTicketNumber = (id) => {
    const tickets = getServices()
    const filterOnlyTicket = tickets.filter((ticket) => ticket.id === id)
    const folio = filterOnlyTicket.map((ticket) => ticket.folio)
    return folio
}
