export const filterDeliveries = (List) => {
  const deliveriesList = []
  const filterOnlyPendientDeliveries = List.filter(
    (item) =>
      item.estado !== 'Envío Completado' && item.estado !== 'En Tránsito'
  )

  filterOnlyPendientDeliveries.forEach(function (item) {
    deliveriesList.push(item)
  })

  const deliveriesSorted = deliveriesList.sort((p1, p2) =>
    p1.date > p2.date ? 1 : p1.date < p2.date ? -1 : 0
  )

  return deliveriesSorted
}
