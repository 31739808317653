import { useState, useLayoutEffect } from 'react'
import { onValue, ref, getDatabase, push, set, remove } from 'firebase/database'
import { database } from '../configs/firebase'

export const useBlog = () => {
  const [blog, setBlog] = useState([])

  useLayoutEffect(() => {
    onValue(
      ref(database, 'blog/'),
      (snapshot) => {
        const blogList = []
        snapshot.forEach((item) => {
          const blogItem = {
            id: item.key,
            contenido: item.val().contenido,
            fechaPublicacion: item.val().fecha,
            tipo: item.val().tipo,
            titulo: item.val().titulo,
          }
          blogList.push(blogItem)
        })
        setBlog(blogList)
      },
      (error) => {
        console.log(error)
      }
    )
  }, [])

  return [blog]
}

export const addBlog = (titulo, tipo, fecha, contenido) => {
  const db = getDatabase()

  push(ref(db, 'blog'), {
    contenido: contenido,
    fecha: fecha,
    tipo: tipo,
    titulo: titulo,
  })
}

export const updateBlog = (id, titulo, tipo, fecha, contenido) => {
  const db = getDatabase()

  set(ref(db, `blog/${id}`), {
    contenido: contenido,
    fecha: fecha,
    tipo: tipo,
    titulo: titulo,
  })
}

export const deleteBlog = (id) => {
  const db = getDatabase()

  remove(ref(db, `blog/${id}`))
}
